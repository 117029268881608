import React, { useState, useEffect } from "react";
import { FormattedMessage, IntlProvider } from "react-intl";
import { FiChevronUp, FiChevronDown } from "react-icons/fi"; // Import the required icons
import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const CustomerDetails = ({ customer }) => {
    const [locale, setLocale] = useState();
    const [showProducts, setShowProducts] = useState({
        contactInfo: true, // First group is open by default
        addressContact: false,
        salesType: false,
        contractInfo: false,
        churnProbability: false,
    });

    useEffect(() => {
        setLocale(localStorage.getItem("language"));
    }, []);

    const toggleGroup = (group) => {
        setShowProducts((prev) => ({ ...prev, [group]: !prev[group] }));
    };

    // Group configurations for conditional rendering
    const groups = [
        {
            id: "contactInfo",
            title: "client.general_info",
            fields: [
                { label: "client.customer_id", value: customer.client_id },
                {
                    label: "client.customer_type",
                    value: customer.client_level_3,
                },
                { label: "client.address", value: customer.address_2 },
            ],
        },
        {
            id: "addressContact",
            title: "client.contact_info",
            fields: [
                { label: "client.contact_name", value: customer.country },
                { label: "client.contact_number", value: customer.phone },
                { label: "client.mail", value: customer.mail },
                { label: "client.website", value: customer.website_url },
            ],
        },
        {
            id: "salesType",
            title: "client.digital",
            fields: [
                { label: "client.portail", value: "Oui" },
                {
                    label: "client.menu",
                    value: customer.menu_url,
                },
            ],
        },
        {
            id: "contractInfo",
            title: "client.contract_info",
            fields: [
                {
                    label: "client.contract_type",
                    value: customer.id === 1 ? "Bière" : "-",
                },
                {
                    label: "client.contract_value",
                    value: customer.id === 1 ? "50 000 €" : "-",
                },
            ],
        },
        {
            id: "visitFrequency",
            title: "client.client_coverage",
            fields: [
                {
                    label: "client.visit_frequency",
                    value: customer.visit_frequency,
                },
                {
                    label: "client.visit_hours",
                    value: customer.visit_hours,
                },
            ],
        },
    ];

    return (
        customer && (
            <IntlProvider locale={locale} messages={messages[locale]}>
                <div className="mt-2 text-black flex-col w-5/6">
                    {groups.map((group) => {
                        // Only render the group if it has fields
                        const hasFields = group.fields.some(
                            (field) => field.value
                        ); // Check if at least one field has a value
                        return (
                            hasFields && (
                                <div className="mb-2" key={group.id}>
                                    <div
                                        className="flex flex-row items-center cursor-pointer w-full border-b border-gray-200"
                                        onClick={() => toggleGroup(group.id)}
                                    >
                                        <h1 className="w-4/5 font-semibold text-sm text-black">
                                            <FormattedMessage
                                                id={group.title}
                                            />
                                        </h1>
                                        <div className="w-1/5 ml-2 text-lg">
                                            {showProducts[group.id] ? (
                                                <FiChevronUp />
                                            ) : (
                                                <FiChevronDown />
                                            )}
                                        </div>
                                    </div>
                                    {showProducts[group.id] && (
                                        <div className="flex flex-col w-full">
                                            {group.fields.map(
                                                (field, index) => (
                                                    <div
                                                        className="mr-2 text-xs flex flex-row w-full mt-1"
                                                        style={{
                                                            minWidth: 120,
                                                        }}
                                                        key={index}
                                                    >
                                                        <div className="flex font-semibold w-1/2">
                                                            <FormattedMessage
                                                                id={field.label}
                                                            />
                                                            {":"}
                                                        </div>
                                                        <div
                                                            className="flex w-1/2"
                                                            style={field.style}
                                                        >
                                                            {field.value &&
                                                            field.value.startsWith(
                                                                "https"
                                                            ) ? (
                                                                <a
                                                                    href={
                                                                        field.value
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="text-blue-500 underline"
                                                                >
                                                                    Lien
                                                                </a>
                                                            ) : (
                                                                field.value
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                                </div>
                            )
                        );
                    })}
                </div>
            </IntlProvider>
        )
    );
};

export default CustomerDetails;

import React from "react";
import { FiBarChart2, FiX } from "react-icons/fi"; // Make sure to install react-icons

const ReportPopUp = ({ togglePopup, reportData }) => {
    const iconCircleStyle = `flex w-8 h-8 bg-gray-100 ml-2 mt-2 rounded-full items-center justify-center`;
    return (
        <div className="flex flex-col w-5/6 justify-center">
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg w-3/4 p-6 relative">
                    {/* Close button */}
                    <button
                        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                        onClick={togglePopup}
                    >
                        <FiX size={24} />
                    </button>

                    {/* Popup content */}
                    <div className="flex flex-row items-center">
                        <div className={iconCircleStyle}>
                            <FiBarChart2 />
                        </div>
                        <h2 className="text-xl text-left  mt-2 ml-4">
                            Compte rendu
                        </h2>
                        {console.log(reportData.responses)}
                    </div>
                    <div className="w-3/4 mt-10 ml-20 mb-20">
                        <table className="table-auto w-full text-lg text-left font-regular border-collapse">
                            <tbody>
                                {Object.entries(
                                    JSON.parse(reportData.responses)
                                ).map(([key, value]) => (
                                    <tr key={key} className="">
                                        <td className="font pr-4 pb-10">
                                            {key}:
                                        </td>
                                        <td>
                                            {value.input_type === "yes_no" &&
                                                (value.value === "oui"
                                                    ? "Oui"
                                                    : "Non")}
                                            {value.input_type === "list" &&
                                                Array.isArray(value.value) && (
                                                    <ul className="list-disc pl-5">
                                                        {value.value.map(
                                                            (item, index) => (
                                                                <li key={index}>
                                                                    {item}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportPopUp;

import React from "react";

function BarIndicator({ percentage }) {
    return (
        <div className="relative w-48 h-4 rounded-full border border-black bg-gradient-to-r from-rose-500 from-35% to-gold to-65%">
            {/* Text centered above the tick mark */}
            <div
                className="absolute text-xxs text-black"
                style={{
                    left: `${percentage}%`,
                    top: "-1rem", // Position above the bar
                    transform: "translateX(-50%)", // Center the text horizontally
                }}
            >
                {percentage}%
            </div>

            {/* Black tick mark */}
            <div
                className="absolute w-1 h-3 bg-black rounded-full"
                style={{
                    left: `${percentage}%`,
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            ></div>
        </div>
    );
}
export default BarIndicator;

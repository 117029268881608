import React, { useState, useEffect } from "react";

interface LocationPointerProps {
	isSelected: boolean;
	isVisited: boolean;
	markerSize: number;
	isFirst: boolean;
	isLast: boolean;
}

const LocationPointer: React.FC<LocationPointerProps> = ({
	isSelected,
	markerSize,
	isVisited,
}) => {
	const [fillColor, setFillColor] = useState<string>("#C3FF36");

	const color = (isSelected: boolean, isVisited: boolean): string => {
		if (isSelected) return "#C3FF36"; // Red for selected
		if (isVisited) return "#C3FF36"; // Gray for visited
		return "#C3FF36"; // Green for default
	};

	useEffect(() => {
		const changeColor = color(isSelected, isVisited);
		setFillColor(changeColor);
	}, [isSelected, isVisited]);

	// if (isFirst) {
	// 	return (
	// 		<svg
	// 			fill="#000000"
    //     width={markerSize}
	// 			height={markerSize}
	// 			viewBox="0 0 24 24"
	// 			id="home-alt-3"
	// 			xmlns="http://www.w3.org/2000/svg"
	// 			className="icon multi-color"
	// 		>
	// 			<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
	// 			<g
	// 				id="SVGRepo_tracerCarrier"
	// 				strokeLinecap="round"
	// 				strokeLinejoin="round"
	// 			></g>
	// 			<g id="SVGRepo_iconCarrier">
	// 				<polygon
	// 					id="secondary-fill"
	// 					points="17 10 17 21 14 21 14 15 10 15 10 21 5 21 5 10 11 4 17 10"
	// 					style={{ fill: fillColor, strokeWidth: "1" }}
	// 				></polygon>
	// 				<polygon
	// 					id="primary-stroke"
	// 					points="19 11 19 21 14 21 14 14 10 14 10 21 5 21 5 11 3 11 12 2 21 11 19 11"
	// 					style={{
	// 						fill: "none",
	// 						stroke: "#2E2D30",
	// 						strokeLinecap: "round",
	// 						strokeLinejoin: "round",
	// 						strokeWidth: 1.3,
	// 					}}
	// 				></polygon>
	// 			</g>
	// 		</svg>
	// 	);
	// } else if (isLast) {
	// 	return (
	// 		<svg
    //   width={markerSize}
    //   height={markerSize}
	// 			viewBox="0 0 24 24"
	// 			fill="none"
	// 			xmlns="http://www.w3.org/2000/svg"
	// 			stroke="#2E2D30"
	// 		>
	// 			<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
	// 			<g
	// 				id="SVGRepo_tracerCarrier"
	// 				strokeLinecap="round"
	// 				strokeLinejoin="round"
	// 			></g>
	// 			<g id="SVGRepo_iconCarrier">
	// 				<path
	// 					opacity="0.5"
	// 					fillRule="evenodd"
	// 					clipRule="evenodd"
	// 					d="M6.5 1.75C6.5 1.33579 6.16421 1 5.75 1C5.33579 1 5 1.33579 5 1.75V21.75C5 22.1642 5.33579 22.5 5.75 22.5C6.16421 22.5 6.5 22.1642 6.5 21.75V13.6V3.6V1.75Z"
	// 					fill={fillColor}
	// 				></path>
	// 				<path
	// 					d="M13.5582 3.87333L13.1449 3.70801C11.5821 3.08288 9.8712 2.9258 8.22067 3.25591L6.5 3.60004V13.6L8.22067 13.2559C9.8712 12.9258 11.5821 13.0829 13.1449 13.708C14.8385 14.3854 16.7024 14.5119 18.472 14.0695L18.5721 14.0445C19.1582 13.898 19.4361 13.2269 19.1253 12.7089L17.5647 10.1078C17.2232 9.53867 17.0524 9.25409 17.0119 8.94455C16.9951 8.81543 16.9951 8.68466 17.0119 8.55553C17.0524 8.24599 17.2232 7.96141 17.5647 7.39225L18.8432 5.26136C19.1778 4.70364 18.6711 4.01976 18.0401 4.17751C16.5513 4.54971 14.9831 4.44328 13.5582 3.87333Z"
	// 					fill={fillColor}
	// 				></path>
	// 			</g>
	// 		</svg>
	// 	);
	// } else {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				version="1.1"
				width={markerSize}
				height={markerSize}
				viewBox="0 0 256 256"
				xmlSpace="preserve"
			>
				<defs></defs>
				<g
					style={{
						stroke: "none",
						strokeWidth: 0,
						strokeDasharray: "none",
						strokeLinecap: "butt",
						strokeLinejoin: "miter",
						strokeMiterlimit: 10,
						fill: "#C3FF36",
						fillRule: "nonzero",
						opacity: 1,
					}}
					transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
				>
					<path
						d="M 45 0 C 27.677 0 13.584 14.093 13.584 31.416 c 0 4.818 1.063 9.442 3.175 13.773 c 2.905 5.831 11.409 20.208 20.412 35.428 l 4.385 7.417 C 42.275 89.252 43.585 90 45 90 s 2.725 -0.748 3.444 -1.966 l 4.382 -7.413 c 8.942 -15.116 17.392 -29.4 20.353 -35.309 c 0.027 -0.051 0.055 -0.103 0.08 -0.155 c 2.095 -4.303 3.157 -8.926 3.157 -13.741 C 76.416 14.093 62.323 0 45 0 z M 45 42.81 c -6.892 0 -12.5 -5.607 -12.5 -12.5 c 0 -6.893 5.608 -12.5 12.5 -12.5 c 6.892 0 12.5 5.608 12.5 12.5 C 57.5 37.202 51.892 42.81 45 42.81 z"
						style={{
							stroke: "#2E2D30",
							strokeWidth: `${isSelected ? 4 : 3}`,
							strokeDasharray: "none",
							strokeLinecap: "butt",
							strokeLinejoin: "miter",
							strokeMiterlimit: 10,
							fill: fillColor,
							fillRule: "nonzero",
							opacity: 1,
						}}
						transform="matrix(1 0 0 1 0 0)"
						strokeLinecap="round"
					/>
				</g>
			</svg>
		);
	// }
};
export default LocationPointer;

import React, { useEffect, useState} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Login from './pages/login';

import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';

import Home from './pages/home';
import ClientDashboard from './pages/clients';
import ErrorPage from './pages/error-page';
import Profile from './pages/profile';
import Leads from './pages/leads';
import Products from './pages/products';
import ManagementDashboard from './pages/management';
import Training from './pages/training/training/index';
import ClientPage from './pages/clientpage';
import Routing from './pages/routing';
import Auth0 from './pages/auth0';
import Chat from './pages/chat';

// Components
import Navigator from './components/Navigator';
import Loader from './components/Loader';
import Footer from './components/Footer';
import Header from './components/Header';

// Styles
import './App.css';
import { FiWifiOff, FiWifi } from 'react-icons/fi';

const Layout = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine); // Initial online status
  const [isSlowConnection, setIsSlowConnection] = useState(false); // For slow connection detection

  // Simulating loading delay
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 100); // Adjust the timeout as needed
    return () => clearTimeout(timeout);
  }, []);

  // Network status detection
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Detect slow internet using the Network Information API
    const handleConnectionChange = () => {
      const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      if (connection) {
        // If the effective connection type is slow or downlink speed is low, show the slow connection message
        const slowConnection = connection.effectiveType === "2g" || connection.effectiveType === "3g" || connection.downlink < 1.5;
        setIsSlowConnection(slowConnection);
      }
    };

    if (navigator.connection) {
      handleConnectionChange(); // Check connection initially
      navigator.connection.addEventListener("change", handleConnectionChange);
    }

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      if (navigator.connection) {
        navigator.connection.removeEventListener("change", handleConnectionChange);
      }
    };
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {!isOnline && (
        <div
          className="flex flex-row items-center justify-center rounded-full bg-black text-white text-xxs w-1/5"
          style={{
            position: "fixed",
            top: "10px", // Slightly away from the top for better visibility
            left: "50%", // Start at the center of the viewport
            transform: "translateX(-50%)", // Offset by half its own width
            textAlign: "center",
            padding: "5px",
            zIndex: 9999,
          }}
        >
          <FiWifiOff /> <span className="ml-3">Vous êtes hors ligne</span>
        </div>
      )}

      {/* Only show the slow connection message if the user is online but has a slow connection */}
      {isOnline && isSlowConnection && (
        <div
          className="flex flex-row items-center justify-center rounded-full bg-yellow-600 text-white text-xxs w-1/3"
          style={{
            position: "fixed",
            top: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            textAlign: "center",
            padding: "5px",
            zIndex: 9999,
          }}
        >
          <FiWifi /> <span className="ml-3">Connexion lente détectée</span>
        </div>
      )}

      <section className="flex flex-row bg-white">
        <div className="z-10 border-r-1 border-white">
          <Navigator />
        </div>
        <section
          className="w-full bg-white flex flex-col h-screen"
          style={{
            overflowY: "auto",
          }}
        >
          <Header />
          <div
            className="w-full bg-white flex flex-col h-screen"
            style={{
              overflowY: "auto",
            }}
          >
            {children}
          </div>
          <div className="flex-grow"></div>
          <Footer />
        </section>
      </section>
    </>
  );
};




const App = () => {
  const { isLoading, isAuthenticated, error } = useAuth0();

  if (isLoading) {
    return <div><Loader/></div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  const ProtectedRoute = ({ isAuthenticated, children }) => {
    if (!isAuthenticated) {
      return <Navigate to="/" replace />;
    }
    return children;
  };

  if (isAuthenticated) {
    return (

      <div className="App bg-white">
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/auth0" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Auth0 /></ProtectedRoute>} />
            <Route exact path="/clients" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ClientDashboard /></ProtectedRoute>} />
            <Route exact path="/home" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Home /></ProtectedRoute>} />
            <Route exact={true} path="*" element={<ErrorPage />} />
            <Route exact path="/profile" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Profile /></ProtectedRoute>} />
            <Route exact path="/leads" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Leads /></ProtectedRoute>} />
            <Route exact path="/products" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Products /></ProtectedRoute>} />
            <Route exact path="/dashboard" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ManagementDashboard /></ProtectedRoute>} />
            <Route exact path="/training" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Training /></ProtectedRoute>} />
            <Route exact path="/client/:clientId" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ClientPage/></ProtectedRoute>} />
            <Route exact path="/routing" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Routing/></ProtectedRoute>} />
            <Route path="/chat" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Chat/></ProtectedRoute>} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
    );
  } else {
    return <div className='App bg-white'><Login /></div>;
  }
};

export default App;

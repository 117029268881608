import React, { useState } from "react";

import { IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";
import { FiMaximize2, FiShoppingBag, FiXCircle } from "react-icons/fi";

const messages = {
    en: messages_en,
    fr: messages_fr,
};

// import PopupModalSmall from "../../components/ModalSmall/PopupModalSmall";

const ProductDetails = ({ product, locale }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        product && (
            <>
                <IntlProvider locale={locale} messages={messages[locale]}>
                    <div className="flex w-full bg-white rounded-lg">
                        <div className="flex flex-row items-center bg-white rounded-lg border border-grey-200 w-full">
                            <img
                                className="w-1/4 object-scale-down pl-2 pr-2"
                                style={{ height: "66.6666%" }}
                                src={product?.image}
                            />

                            <div className="flex flex-col w-3/4 float-left text-xs">
                                <div className="flex justify-end mr-2">
                                    <button
                                        className="flex flex-row justify-end mt-2 px-2 py-1 text-lg w-1/4"
                                        onClick={() => setShowModal(true)} // Set modal visibility to true
                                    >
                                        <FiMaximize2 />{" "}
                                    </button>
                                </div>
                                <div className="flex flex-row items-center mb-2 pr-2">
                                    <p className="text-base text-black font-semibold">
                                        {product?.product_name}
                                    </p>
                                    {product?.promotion && (
                                        <div className="bg-rose-500 rounded-full text-white px-2 py-1 text-xxs ml-3">
                                            Promotion %
                                        </div>
                                    )}
                                </div>
                                <div className="flex flex-row items-start items-center text-gray-600 text-sm mr-4">
                                    Réf: {product?.product_id}{" "}
                                </div>
                                <div className="flex flex-row items-start items-center text-black text-sm mr-4">
                                    {product?.product_desc}{" "}
                                </div>
                                <div className="flex justify-end mb-2 mr-4 mt-4 mb-4">
                                    <button
                                        className="flex flex-row items-center justify-center ml-10 bg-white border border-black text-black rounded-full px-2 py-1 text-base w-1/3"
                                        onClick={() =>
                                            window.open(
                                                product?.shop_url,
                                                "_blank"
                                            )
                                        }
                                    >
                                        <FiShoppingBag />{" "}
                                        <div className="ml-2">Acheter</div>
                                    </button>
                                </div>
                            </div>
                            {showModal && ( // Render modal if showModal is true
                                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                                    <div className="bg-white p-6 rounded-lg w-1/2 h-50">
                                        <div className="flex flex-row w-full items-center ">
                                            <div className="w-2/6">
                                                <img
                                                    className="w-full object-scale-down mb-4"
                                                    src={product?.image}
                                                    alt={product?.product_name}
                                                />
                                            </div>
                                            <div className="w-4/6 flex flex-col">
                                                <div className="flex justify-end">
                                                    {" "}
                                                    <button
                                                        className="text-gray-500 hover:text-gray-700 text-xxl"
                                                        onClick={() =>
                                                            setShowModal(false)
                                                        } // Close modal on click
                                                    >
                                                        <FiXCircle />
                                                    </button>
                                                </div>
                                                <div className="flex flex-row items-center mb-2">
                                                    <p className="text-xl">
                                                        {product?.product_name}
                                                    </p>
                                                    {product?.product_id ==
                                                        1 && (
                                                        <div className="bg-rose-500 rounded-full text-white px-2 py-1 text-xxs ml-3">
                                                            Promotion %
                                                        </div>
                                                    )}
                                                </div>

                                                <p className="mb-2">
                                                    <strong>Réf:</strong>{" "}
                                                    {product?.product_id}
                                                </p>
                                                <p className="mb-2">
                                                    <strong>
                                                        Description:
                                                    </strong>{" "}
                                                    {product?.product_desc}
                                                </p>
                                                <div className="flex flex-row w-full mt-2">
                                                    <div className="w-1/4">
                                                        <p className="mb">
                                                            <strong>
                                                                Prix:
                                                            </strong>{" "}
                                                        </p>
                                                    </div>
                                                    <div className="w-3/4">
                                                        <ul className="w-full text-xs">
                                                            {/* Header Row */}
                                                            <li className="w-full flex items-center text-sm font-bold border-b border-gray-300 ">
                                                                <div className="w-1/2">
                                                                    Tarif
                                                                </div>
                                                                <div className="w-1/2">
                                                                    Prix
                                                                </div>
                                                            </li>

                                                            <li className="w-full flex items-center py-1">
                                                                <div className="w-1/2">
                                                                    1
                                                                </div>
                                                                <div className="w-1/2">
                                                                    12€
                                                                </div>
                                                            </li>
                                                            <li className="w-full flex items-center py-1">
                                                                <div className="w-1/2">
                                                                    2
                                                                </div>
                                                                <div className="w-1/2">
                                                                    10€
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="flex flex-row w-full mt-2">
                                                    <div className="w-1/4">
                                                        <p className="mb">
                                                            <strong>
                                                                Stock:
                                                            </strong>{" "}
                                                        </p>
                                                    </div>
                                                    <div className="w-3/4">
                                                        <ul className="w-full text-xs">
                                                            {/* Header Row */}
                                                            <li className="w-full flex items-center text-sm font-bold border-b border-gray-300">
                                                                <div className="w-1/2">
                                                                    Dépôt
                                                                </div>
                                                                <div className="w-1/2">
                                                                    Quantité
                                                                </div>
                                                            </li>

                                                            {/* Data Rows */}
                                                            {product?.stock.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="w-full flex items-center py-1"
                                                                    >
                                                                        <div className="w-1/2">
                                                                            {
                                                                                item.warehouse_id
                                                                            }
                                                                        </div>
                                                                        <div className="w-1/2">
                                                                            {
                                                                                item.quantity
                                                                            }
                                                                        </div>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <p className="mb-4">
                                                    <strong>
                                                        Acheter avec:
                                                    </strong>{" "}
                                                </p>
                                                <div className="flex flex-row">
                                                    <div className="w-1/6">
                                                        <img src="https://media-pim.c10.fr/resized/700x700/catalog/product/6/8/7/4/1200044786_1_3_Thomas_Henry_Tonic_Water_200ml_glass_1714984742217.jpg" />
                                                    </div>
                                                    <div className="w-1/5 text-sm">
                                                        Tonic Thomas Henry Tonic
                                                        Water Tonic Water
                                                    </div>
                                                    <div className="w-1/6">
                                                        <img src="https://media-pim.c10.fr/resized/700x700/catalog/product/1/7/2/6/1200026271_1_3_5060108450003_face_1720092787618.jpg" />
                                                    </div>
                                                    <div className="w-1/5 text-sm">
                                                        Tonic Fever Tree Tonic
                                                        Water
                                                    </div>
                                                </div>
                                                <div className="flex justify-end">
                                                    <button
                                                        className="flex flex-row items-center ml-10 bg-black text-white justify-center rounded-full px-2 py-1 text-base w-1/4"
                                                        onClick={() =>
                                                            window.open(
                                                                product?.shop_url,
                                                                "_blank"
                                                            )
                                                        }
                                                    >
                                                        <FiShoppingBag />{" "}
                                                        <span className="ml-2 text-base">
                                                            Acheter
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </IntlProvider>
            </>
        )
    );
};

export default ProductDetails;

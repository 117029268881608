import React, { useState } from "react";
import ReportPopUp from "../../ModalComponents/ReportPopUp";
import { FiExternalLink } from "react-icons/fi";

const VisitHistory = ({ visithistory }) => {
    // Get today's date in the YYYY-MM-DD format
    const today = new Date();
    today.setHours(23, 59, 0, 0); // Set the time to 23:59 to compare only dates

    // Filter out visits that occur in the future
    const pastVisits = visithistory
        ? visithistory.filter((visit) => {
              const visitDate = new Date(visit.start_date);
              return visitDate <= today;
          })
        : [];

    const [selectedReport, setSelectedReport] = useState(null); // Track selected report data

    const togglePopup = (reportData) => {
        if (reportData) {
            setSelectedReport(reportData); // Set the selected report data
        } else {
            setSelectedReport(null); // Clear the selected report data
        }
    };
    console.log(pastVisits);

    return (
        <div className="pt-10 ml-10 mr-10 w-5/6">
            {pastVisits.map((visit, index) => (
                <div
                    key={visit.meeting_id || index}
                    className="list-wrapper mb-10 text-xs"
                >
                    <div className="flex flex-row justify-between items-center border-b border-gray-200 pt-2 pb-2 text-left">
                        <div className="w-1/3 text-sm font-semibold">
                            {visit.start_date.split("T")[0]}
                        </div>
                        <div className="w-1/3 text-sm font-semibold">
                            {visit.user.first_name} {visit.user.last_name}
                        </div>
                        <div className="w-1/3 text-sm font-semibold flex items-center">
                            {visit.type && visit.type.description && (
                                <>
                                    {visit.type.description}
                                    <button
                                        onClick={() =>
                                            togglePopup(visit.report)
                                        } // Pass report data
                                        className="ml-2 p-1 bg-blue-500 text-black text-xs rounded ml-20"
                                    >
                                        <FiExternalLink />
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            ))}
            {selectedReport && (
                <ReportPopUp
                    togglePopup={() => togglePopup(null)} // Close popup when toggled
                    reportData={selectedReport} // Pass the selected report data
                />
            )}
        </div>
    );
};

export default VisitHistory;

import React from "react";

import { FiHome, FiUsers, FiBook, FiCalendar, FiFilter } from "react-icons/fi";

import { Link } from "react-router-dom";

function Footer() {
    return (
        <footer
            style={{ flexShrink: 0, width: "95%" }}
            className="text-center text-black z-50 bg-gray-200 sticky border-r-1 rounded-full m-4 border-white md:hidden"
        >
            <div className="flex flex-row w-full items-center pb-4 pt-4 text-xl">
                <div className="flex flex-col items-center w-1/5">
                    <Link to="/home">
                        <FiHome />
                    </Link>
                </div>
                <div className="flex flex-col items-center w-1/5">
                    <Link to="/routing">
                        <FiCalendar />
                    </Link>
                </div>
                <div className="flex flex-col items-center w-1/5">
                    <Link to="/my-leads">
                        <FiFilter />
                    </Link>
                </div>
                <div className="flex flex-col items-center w-1/5">
                    <Link to="/clients">
                        <FiUsers />
                    </Link>
                </div>
                <div className="flex flex-col items-center w-1/5">
                    <Link to="/products">
                        <FiBook />
                    </Link>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

import React, { useState, useEffect } from "react";

import api from "./mockAPI";
import database from "./mockData";

const Channel = ({ chatId, clientName, header = true }) => {
    console.log(chatId);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [showUserList, setShowUserList] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);

    useEffect(() => {
        setMessages(api.getMessages(chatId));
        console.log(api.getMessages(1));
    }, [chatId]);

    const handleInputChange = (e) => {
        const input = e.target.value;
        setNewMessage(input);

        // Detect @ and show user list
        const match = input.match(/@(\w*)$/);
        if (match) {
            const query = match[1].toLowerCase();
            const usersToShow = database.users.filter((user) =>
                user.username.toLowerCase().includes(query)
            );
            setFilteredUsers(usersToShow);
            setShowUserList(true);
        } else {
            setShowUserList(false);
        }
    };

    const handleUserSelect = (username) => {
        // Replace @query with @username
        const updatedMessage = newMessage.replace(/@\w*$/, `@${username} `);
        setNewMessage(updatedMessage);
        setShowUserList(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newMsg = api.postMessage({
            chatId,
            userId: "1",
            content: newMessage,
        });
        setMessages([...messages, newMsg]);
        setNewMessage("");
    };
    console.log(messages);

    return (
        <div className="flex flex-col h-full">
            {header && (
                <h3 className="p-4 text-cl font-bold text-left w-full border-b gray-300">
                    {clientName}
                </h3>
            )}
            {/* Messages */}
            <div className="flex-grow overflow-y-auto overflow-x-auto p-4 w-full">
                {messages.map((msg) => {
                    // Find the user by user_id
                    const user = database.users.find(
                        (u) => u.id === parseInt(msg.user_id)
                    );
                    return (
                        <div
                            key={msg.id}
                            className={`mb-1 flex flex-col items-${
                                msg.user_id === "1" ? "end" : "start"
                            }`}
                            style={{
                                maxWidth: "100%",
                                wordBreak: "break-word",
                            }}
                        >
                            {msg.user_id !== "1" && (
                                <div className="text-xs text-gray-500 mb-1 ml-3 font-bold">
                                    {user?.username || `User ${msg.user_id}`}
                                </div>
                            )}
                            <div
                                className={`rounded-full px-6 py-1 text-left ${
                                    msg.user_id === "1"
                                        ? "text-white bg-black"
                                        : "bg-gray-200 text-black"
                                }`}
                            >
                                <div
                                    className="text-sm"
                                    dangerouslySetInnerHTML={{
                                        __html: msg.content.replace(
                                            /@(\w+)/g,
                                            `<strong>@$1</strong>`
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>

            {/* Message Input */}
            <form
                onSubmit={handleSubmit}
                className="relative flex items-center p-4 bg-white border-t border-gray-300"
            >
                <input
                    type="text"
                    value={newMessage}
                    onChange={handleInputChange}
                    placeholder="Tapez un message......"
                    className="flex-1 p-2 border border-gray-300 rounded-full text-sm"
                />
                <button
                    type="submit"
                    className="ml-2 px-4 py-2 bg-black text-white rounded-full text-sm"
                >
                    Envoyer
                </button>

                {/* User List Dropdown */}
                {showUserList && (
                    <div className="absolute bottom-16 left-4 w-64 bg-white border border-gray-300 rounded-md shadow-lg max-h-40 overflow-auto text-xs">
                        {filteredUsers.length > 0 ? (
                            filteredUsers.map((user) => (
                                <div
                                    key={user.id}
                                    onClick={() =>
                                        handleUserSelect(user.username)
                                    }
                                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                >
                                    @{user.username}
                                </div>
                            ))
                        ) : (
                            <div className="px-4 py-2 text-gray-500">
                                No users found
                            </div>
                        )}
                    </div>
                )}
            </form>
        </div>
    );
};

export default Channel;

/*eslint-disable*/
import React, { useState } from "react";
import FastAPIClient from "../../../client";
import config from "../../../config";
const client = new FastAPIClient(config);
const { v4: uuidv4 } = require("uuid");

import { FormattedMessage, IntlProvider } from "react-intl";
import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    fr: messages_fr,
};

const ButtonComponent = ({ selectedValue, onChange }) => {
    const handleButtonClick = (value) => {
        onChange(value);
    };

    // Styles for the buttons
    const getButtonStyles = (value) => {
        if (selectedValue === null) {
            return "bg-white border border-gray-600 text-gray-600"; // Both gray if none is selected
        }
        if (selectedValue === value) {
            return value === "oui"
                ? "bg-gold text-black" // Oui selected
                : "bg-red-500 text-white"; // Non selected
        }
        return "bg-white border border-gray-600 text-gray-600"; // Default gray
    };

    return (
        <div className="flex space-x-4">
            <button
                onClick={() => handleButtonClick("oui")}
                className={`py-1 rounded-full px-6 ${getButtonStyles("oui")}`}
            >
                Oui
            </button>
            <button
                onClick={() => handleButtonClick("non")}
                className={`py-1 rounded-full px-6 ${getButtonStyles("non")}`}
            >
                Non
            </button>
        </div>
    );
};

const ReportForm = ({
    reportlayout,
    setToggle,
    clientId,
    userId,
    meetingId,
    userName,
    meeting_type_id,
    locale = "fr",
}) => {
    const fields = reportlayout;
    const [responses, setResponses] = useState({});
    const [selectedLayout, setSelectedLayout] = useState(
        meeting_type_id ? String(meeting_type_id) : null
    ); // Ensure meeting_type_id is a string
    // const [layouts, setLayouts] = useState([]); // State to hold the list of report layouts

    // Filter fields based on the selected layout (meeting_type_id)
    const target_layout = selectedLayout
        ? fields.filter((field) => field.meeting_type_id === selectedLayout)
        : fields; // Show all fields if no layout is selected

    // Get current date formatted as MM/DD/YYYY
    const currentDate = new Date().toLocaleDateString("fr-FR");

    // Handle input changes and update the response state
    const handleInputChange = (fieldName, value, inputType) => {
        setResponses((prev) => ({
            ...prev,
            [fieldName]: {
                value: value,
                input_type: inputType, // Store input_type as well
            },
        }));
    };

    // Handle form submission
    const handleSaveVisit = async () => {
        // Prepare data payload
        const payload = {
            report_id: uuidv4(),
            meeting_id: meetingId || uuidv4(), // use provided meeting_id or create a new one
            client_id: clientId, // Replace with actual client_id
            user_id: userId, // Replace with actual user_id
            responses: JSON.stringify(responses),
            created_at: new Date().toISOString(),
        };

        // Send data to backend
        await client.saveReport(payload);

        setToggle(false);
    };

    const [selectedResponse, setSelectedResponse] = useState(null);

    return (
        <section className="w-full bg-white flex flex-col items-center mt-5">
            <IntlProvider locale={locale} messages={messages[locale]}>
                <div className="flex flex-col w-full md:w-4/5 items-center mt-4">
                    <div className="mt-4 text-left text-xl w-full">
                        <FormattedMessage
                            id="report.title"
                            values={{ number: 1 }}
                        />
                    </div>
                    <div className="flex flex-row text-left w-full mt-5">
                        <div className="w-1/2">
                            {" "}
                            <FormattedMessage
                                id="report.visit_date"
                                values={{ number: 1 }}
                            />
                        </div>
                        <div className="w-1/2 text-gray-600">{currentDate}</div>
                    </div>
                    <div className="flex flex-row text-left w-full mt-5">
                        <div className="w-1/2">
                            {" "}
                            <FormattedMessage
                                id="report.attendees"
                                values={{ number: 1 }}
                            />
                        </div>
                        <div className="w-1/2 text-gray-600">{userName}</div>
                    </div>

                    {/* Conditionally render the dropdown if meetingId is null */}
                    {meetingId === null && (
                        <div className="mt-5 w-full flex flex-row">
                            <div className="w-1/2">
                                <label
                                    htmlFor="layout"
                                    className="text-left block"
                                >
                                    <FormattedMessage
                                        id="report.select_report"
                                        values={{ number: 1 }}
                                    />
                                </label>
                            </div>
                            <div className="w-1/2 text-left">
                                <select
                                    id="layout"
                                    className="border border-gray-300 p-2 rounded-full"
                                    value={selectedLayout}
                                    onChange={(e) =>
                                        setSelectedLayout(e.target.value)
                                    }
                                >
                                    <option value="">
                                        {" "}
                                        <FormattedMessage
                                            id="report.select_report"
                                            values={{ number: 1 }}
                                        />
                                    </option>
                                    {reportlayout.map((layout) => (
                                        <option
                                            key={layout.meeting_type_id}
                                            value={layout.meeting_type_id}
                                        >
                                            {layout.meeting_type_id}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    )}

                    {selectedLayout && (
                        <div className="w-full mb-5 text-left">
                            {target_layout.map((field) => (
                                <div
                                    key={field.field_id}
                                    className="flex flex-row mr-4 w-full mt-5"
                                >
                                    <div className="w-1/2">
                                        {field.field_name}
                                    </div>

                                    {field.input_type === "text" && (
                                        <input
                                            type="text"
                                            placeholder={
                                                field.field_description
                                            }
                                            className="border border-gray-300 p-2 rounded"
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.field_name, // Use field_name as key
                                                    e.target.value,
                                                    field.input_type // Store input_type
                                                )
                                            }
                                        />
                                    )}

                                    {field.input_type === "yes_no" && (
                                        <ButtonComponent
                                            selectedValue={selectedResponse}
                                            onChange={(value) => {
                                                setSelectedResponse(value);
                                                handleInputChange(
                                                    field.field_name,
                                                    value,
                                                    field.input_type
                                                );
                                            }}
                                        />
                                    )}

                                    {field.input_type === "list" && (
                                        <div className="flex flex-col">
                                            {field.input_options
                                                .split(",")
                                                .map((option) => (
                                                    <label
                                                        key={option.trim()}
                                                        className="flex items-center"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            className="mr-2"
                                                            checked={
                                                                responses[
                                                                    field
                                                                        .field_name
                                                                ]?.value?.includes(
                                                                    option.trim()
                                                                ) || false
                                                            }
                                                            onChange={(e) => {
                                                                const selected =
                                                                    responses[
                                                                        field
                                                                            .field_name
                                                                    ]?.value ||
                                                                    [];
                                                                // Update the response value for this field with the selected options
                                                                handleInputChange(
                                                                    field.field_name, // Use field_name as key
                                                                    e.target
                                                                        .checked
                                                                        ? [
                                                                              ...selected,
                                                                              option.trim(),
                                                                          ]
                                                                        : selected.filter(
                                                                              (
                                                                                  opt
                                                                              ) =>
                                                                                  opt !==
                                                                                  option.trim()
                                                                          ),
                                                                    field.input_type // Store input_type
                                                                );
                                                            }}
                                                        />
                                                        {option.trim()}
                                                    </label>
                                                ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="flex flex-row w-full md:w-4/5 mt-5 mb-10">
                        <div className="w-3/4"></div>
                        <div className="w-1/4 flex flex-col items-end">
                            <button
                                onClick={handleSaveVisit}
                                className="w-1/2 bg-black rounded-full text-white pl pr pt-1 pb-1 font-semibold align-right"
                            >
                                <FormattedMessage
                                    id="report.save"
                                    values={{ number: 1 }}
                                />
                            </button>
                        </div>
                        <div className="w-1/4 flex flex-col items-end">
                            <button
                                onClick={() => setToggle(false)}
                                className="w-1/2 bg-black rounded-full text-white pl pr pt-1 pb-1 font-semibold align-right"
                            >
                                <FormattedMessage
                                    id="report.cancel"
                                    values={{ number: 1 }}
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </IntlProvider>
        </section>
    );
};

export default ReportForm;

import React, { useState } from "react";
import {
    FiArrowUp,
    FiArrowDown,
    FiBarChart2,
    FiX,
    FiChevronUp,
    FiChevronDown,
} from "react-icons/fi"; // Make sure to install react-icons

import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import BarIndicator from "../../Charts/BarIndicator";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const ClientStatisticsPopUp = ({ statistics, togglePopup }) => {
    const clientstatistics = statistics;

    const marginChartStat = statistics.find(
        (stat) => stat.statistic_type === "margin_chart"
    );

    const revenueChartStat = statistics.find(
        (stat) => stat.statistic_type === "revenue_chart"
    );

    const indicators = statistics.filter(
        (stat) => stat.statistic_type === "indicator_chart"
    );

    const stacked_options = {
        plugins: {
            legend: {
                position: "right",
                display: false,
            },
            title: {
                display: false,
                text: "Chart.js Bar Chart - Stacked",
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: true, // Show horizontal grid lines
                    color: "rgba(0, 0, 0, 0.1)", // Light gray color for subtle lines
                    lineWidth: 1, // Customize line thickness
                    borderDash: [5, 5], // Optional: dashed lines for a lighter look
                },
            },
        },
        maintainAspectRatio: false, // Allow control over width and height
    };

    const [openGroups, setOpenGroups] = useState({});

    // Function to toggle a group
    const toggleGroup = (category) => {
        setOpenGroups((prev) => ({
            ...prev,
            [category]: !prev[category],
        }));
    };

    // Group stats by product_category
    const groupedStats = statistics.reduce((acc, stat) => {
        if (stat.statistic_type === "product_level_comparison") {
            const category = stat.data.product_category;
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(stat);
        }
        return acc;
    }, {});

    const iconCircleStyle = `flex w-8 h-8 bg-gray-100 ml-2 mt-2 rounded-full items-center justify-center`;
    return (
        <div className="flex flex-col w-5/6 justify-center">
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg w-3/4 p-6 relative">
                    {/* Close button */}
                    <button
                        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                        onClick={togglePopup}
                    >
                        <FiX size={24} />
                    </button>

                    {/* Popup content */}
                    <div className="flex flex-row items-center">
                        <div className={iconCircleStyle}>
                            <FiBarChart2 />
                        </div>
                        <h2 className="text-xl text-left  mt-2 ml-4">
                            Statistiques
                        </h2>
                    </div>
                    <div className="text-left justify-center mt-5 mb-5">
                        <div className="flex flex-row w-5/6">
                            {/* Render statistics data passed to component */}
                            <div className="w-1/2 ml-20">
                                <div className="text-">Aperçu</div>
                                <div className="w-full flex flex-row justify-between items-center mb-2 ">
                                    <div className="flex text-left text-sm font-semibold w-2/3 "></div>
                                    <div
                                        className={`text-sm  w-12 h-5 flex item-center justify-center `}
                                    >
                                        2024
                                    </div>
                                    <div
                                        className={`flex items-center text-sm font-semibold w-1/6 ml-2`}
                                    ></div>
                                    <div
                                        className={`text-sm  w-12 h-5 flex justify-center w-1/6`}
                                    >
                                        2023
                                    </div>
                                </div>
                                {clientstatistics.map((stat, index) => (
                                    <div key={index} className=" text-xs  mb-5">
                                        {stat.statistic_type ==
                                            "comparison" && (
                                            <div className="w-full flex flex-row justify-between items-center mb-5">
                                                <div className="text-left  w-2/3">
                                                    {stat.data.name}
                                                </div>
                                                <div
                                                    className={`rounded-full ${
                                                        stat.data.change > 0
                                                            ? "bg-gold text-black"
                                                            : "bg-rose-500 text-white"
                                                    } text-xxs font-bold w-12 h-5 flex items-center justify-center`}
                                                >
                                                    {parseFloat(
                                                        stat.data.value
                                                    ).toFixed(0)}
                                                </div>
                                                <div
                                                    className={`flex items-center ${
                                                        stat.data.change > 0
                                                            ? "text-gold"
                                                            : "text-rose-500"
                                                    } text-xs font-extrabold w-1/5 ml-2`}
                                                >
                                                    {stat.data.change > 0 ? (
                                                        <>
                                                            <span className="ml-1">
                                                                {
                                                                    stat.data
                                                                        .change
                                                                }
                                                                %
                                                            </span>
                                                            <FiArrowUp />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className="ml-1">
                                                                {
                                                                    stat.data
                                                                        .change
                                                                }
                                                                %
                                                            </span>
                                                            <FiArrowDown />
                                                        </>
                                                    )}
                                                </div>
                                                <div
                                                    className={`rounded-full bg-gray-200 text-xxs font-bold w-12 h-5 flex items-center justify-center`}
                                                >
                                                    {parseFloat(
                                                        stat.data.value2022
                                                    ).toFixed(0)}
                                                </div>
                                            </div>
                                        )}

                                        {stat.statistic_type == "indicator" && (
                                            <div className="w-full flex flex-row justify-between items-center mb-5 ">
                                                <div className="text-left text-xs  w-2/3">
                                                    {stat.data.name}
                                                </div>
                                                <div
                                                    className={`rounded-full ${
                                                        stat.data.value ==
                                                        "Faible"
                                                            ? "bg-gold"
                                                            : "bg-rose-500"
                                                    } text-xxs font-bold w-12 h-5 flex items-center justify-center`}
                                                >
                                                    {stat.data.value}
                                                </div>
                                                <div
                                                    className={`flex items-center ${
                                                        stat.data.change > 0
                                                            ? "text-gold"
                                                            : "text-rose-500"
                                                    } text-xs font-bold w-1/5 ml-2`}
                                                ></div>
                                                <div
                                                    className={`rounded-full
                                                        text-xxs font-bold w-12 h-5 flex items-center justify-center`}
                                                ></div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                Statistiques par categories produits
                                <div>
                                    {Object.entries(groupedStats).map(
                                        ([category, stats]) => (
                                            <div
                                                key={category}
                                                className="text-xs mb-3 mt-3 "
                                            >
                                                {/* Group Header */}
                                                <div
                                                    className="cursor-pointer flex justify-between items-center rounded"
                                                    onClick={() =>
                                                        toggleGroup(category)
                                                    }
                                                >
                                                    <div className="">
                                                        {category}
                                                    </div>
                                                    <div>
                                                        {openGroups[
                                                            category
                                                        ] ? (
                                                            <FiChevronUp />
                                                        ) : (
                                                            <FiChevronDown />
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Group Content */}
                                                {openGroups[category] && (
                                                    <div className="ml-10 mt-2">
                                                        {stats.map((stat) => (
                                                            <div
                                                                key={
                                                                    stat.data
                                                                        .name
                                                                }
                                                                className="w-full flex h-5 flex-row justify-between items-center text-xxs"
                                                            >
                                                                <div className="text-left w-2/3">
                                                                    {
                                                                        stat
                                                                            .data
                                                                            .name
                                                                    }{" "}
                                                                </div>
                                                                <div
                                                                    className={`rounded-full ${
                                                                        stat
                                                                            .data
                                                                            .change >
                                                                        0
                                                                            ? "bg-gold text-black"
                                                                            : "bg-rose-500 text-white"
                                                                    } text-xxs font-bold w-12 flex items-center justify-center`}
                                                                >
                                                                    {parseFloat(
                                                                        stat
                                                                            .data
                                                                            .value
                                                                    ).toFixed(
                                                                        0
                                                                    )}
                                                                </div>
                                                                <div
                                                                    className={`flex items-center ${
                                                                        stat
                                                                            .data
                                                                            .change >
                                                                        0
                                                                            ? "text-gold"
                                                                            : "text-rose-500"
                                                                    } text-xxs font-extrabold w-1/5 ml-2`}
                                                                >
                                                                    {stat.data
                                                                        .change >
                                                                    0 ? (
                                                                        <>
                                                                            <span className="ml-1">
                                                                                {
                                                                                    stat
                                                                                        .data
                                                                                        .change
                                                                                }

                                                                                %
                                                                            </span>
                                                                            <FiArrowUp />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <span className="ml-1">
                                                                                {
                                                                                    stat
                                                                                        .data
                                                                                        .change
                                                                                }

                                                                                %
                                                                            </span>
                                                                            <FiArrowDown />
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div
                                                                    className={`rounded-full bg-gray-200 text-xxs font-bold w-12 flex items-center justify-center`}
                                                                >
                                                                    {parseFloat(
                                                                        stat
                                                                            .data
                                                                            .value2022
                                                                    ).toFixed(
                                                                        0
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col w-1/2 ml-20">
                                {/* Revenue Bar Chart */}
                                <div className="flex flex-col mb-6 w-1/2">
                                    <div className=" text mb-2">
                                        Chiffre d&apos;affaires (€)
                                    </div>
                                    <div
                                        style={{
                                            minHeight: 150,
                                            minWidth: 450,
                                        }}
                                    >
                                        <Bar
                                            data={revenueChartStat.data}
                                            options={stacked_options}
                                        />
                                    </div>
                                </div>

                                {/* Margin Percentage Bar Chart */}
                                <div className="flex flex-col mb-6 w-1/2">
                                    <div className=" text mb-2">
                                        Volume (hL)
                                    </div>
                                    <div
                                        style={{
                                            minHeight: 150,
                                            minWidth: 450,
                                        }}
                                    >
                                        <Bar
                                            data={marginChartStat.data}
                                            options={stacked_options}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="text mb-5">
                                        Potentiel vs clients similaires
                                    </div>

                                    {indicators.map((indicator, index) => (
                                        <div
                                            key={index}
                                            className="flex flex-row items-center w-full mb-4"
                                        >
                                            <div className="w-1/2 text-sm">
                                                {indicator.data.name}
                                            </div>
                                            <BarIndicator
                                                percentage={
                                                    indicator.data.value
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientStatisticsPopUp;

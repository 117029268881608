/*eslint-disable*/

import React, { useEffect, useState } from "react";
import {
    FiLogOut,
    FiHome,
    FiTool,
    FiUsers,
    FiBook,
    FiFilter,
    FiCalendar,
    FiMessageSquare,
    FiUserPlus,
} from "react-icons/fi";

import { PiGraduationCapBold } from "react-icons/pi";

import { FaGraduationCap } from "react-icons/fa";

import { VscBlank } from "react-icons/vsc";

import { useAuth0 } from "@auth0/auth0-react";

import { CgPerformance } from "react-icons/cg";
import {
    Sidebar,
    Menu,
    MenuItem,
    sidebarClasses,
    SubMenu,
    menuClasses,
} from "react-pro-sidebar";
import { useNavigate, Link } from "react-router-dom";
import FastAPIClient from "../../client";
import config from "../../config";

const client = new FastAPIClient(config);

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    fr: messages_fr,
};

import build_param from "../../build_params.json";

const frontendPath = build_param.frontendPath;

function Navigator() {
    const [layout, setLayout] = useState([]);
    const [color, setColor] = useState();

    const [loading, setLoading] = useState(false);

    const [locale, setLocale] = useState();
    const [tokenFlag, setToken] = useState(false);

    const navigate = useNavigate();

    const { getAccessTokenSilently } = useAuth0();

    const [leadNotification, setLeadNotification] = useState(false);
    const [recommendationNotification, setRecommendationNotification] =
        useState(false);

    const handleLogout = () => {
        client.logout();

        navigate("/");
    };

    // color coding
    const bgColor = "#F5F5F5";
    const hoverColor = "white";

    const { logout } = useAuth0();

    const logout_auth0 = async () => {
        localStorage.removeItem("token");
        localStorage.removeItem("token_test");
        localStorage.removeItem("user");
        localStorage.removeItem("layoutData");
        localStorage.removeItem("language");
        logout({
            logoutParams: {
                returnTo: `${frontendPath}/`,
            },
        });
    };

    // Function to fetch user data and save it in localStorage
    const fetchUserData = async () => {
        try {
            const data = await client.fetchUser();

            // Set user state
            await setUser(data);
            // Save user data in localStorage
            localStorage.setItem("user", JSON.stringify(data));
            localStorage.setItem("language", data.language);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    // Function to check if user data is present in localStorage
    const getUserDataFromLocalStorage = () => {
        const storedUserData = localStorage.getItem("user");

        if (storedUserData) {
            // If data is present, parse and return it
            return JSON.parse(storedUserData);
        }

        // If data is not present, return null or handle accordingly
        return null;
    };

    useEffect(() => {
        const fetchAccessToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: "https://www.api.myway.technology",
                        scope: "read:appointments",
                    },
                });

                localStorage.setItem("token", accessToken);
                // You can set the current time using the moment library here if needed
            } catch (error) {
                console.error("Failed to fetch access token:", error);
            }
        };
        // localStorage.setItem("language", "hase");
        const tokenData = localStorage.getItem("token");

        // Check if tokenData is empty or null
        if (tokenData == null || tokenData == undefined) {
            fetchAccessToken();
            setToken(true);
        } else {
            setToken(true);
        }
    }, []); // Empty dependency array ensures this effect runs only once on component mount

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Check if user data is present in localStorage
                const userDataFromLocalStorage = getUserDataFromLocalStorage();

                if (userDataFromLocalStorage) {
                    // If data is present, use it directly
                    setUser(userDataFromLocalStorage);

                    const storedLanguage = localStorage.getItem("language");
                    setLocale(storedLanguage);

                    // Simulate asynchronous behavior with a delay
                    await new Promise((resolve) => setTimeout(resolve, 1000));

                    setLoading(false);
                } else {
                    // If data is not present, fetch it
                    await fetchUserData();

                    // Simulate asynchronous behavior with a delay
                    await new Promise((resolve) => setTimeout(resolve, 1000));

                    // const storedLanguage =
                    await setLocale(localStorage.getItem("language"));

                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle errors here
            }
        };

        // Call the asynchronous function
        fetchData();

        // ... (other useEffect logic)
    }, [tokenFlag]); // Empty dependency array means it runs only on mount

    const [user, setUser] = useState(false);

    // Function to fetch layout and save it in localStorage
    const fetchLayout = async () => {
        try {
            const data = await client.getLayout();
            // Save layout data in localStorage
            localStorage.setItem("layoutData", JSON.stringify(data));
            // Set layout state
            setLayout(data);
        } catch (error) {
            console.error("Error fetching layout:", error);
        }
    };

    // Function to check if layout data is present in localStorage
    const getLayoutDataFromLocalStorage = () => {
        const storedLayoutData = localStorage.getItem("layoutData");

        if (storedLayoutData) {
            // If data is present, parse and return it
            return JSON.parse(storedLayoutData);
        }

        // If data is not present, return null or handle accordingly
        return null;
    };

    useEffect(() => {
        // Check if layout data is present in localStorage
        const layoutDataFromLocalStorage = getLayoutDataFromLocalStorage();

        if (layoutDataFromLocalStorage) {
            // If data is present, use it directly
            setLayout(layoutDataFromLocalStorage);
            // setColor(layoutDataFromLocalStorage[0]?.client);
            // setColor(layoutDataFromLocalStorage[0]?.client);
        } else {
            // If data is not present, fetch it
            fetchLayout();
        }

        // ... (other useEffect logic)
    }, [tokenFlag]); // Empty dependency array means it runs only on mount

    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            // You can adjust the breakpoint value as needed
            const breakpoint = 1366; // Example breakpoint value

            // Update the collapsed state based on the screen width
            setCollapsed(window.innerWidth <= breakpoint);
        };

        // Initial call to set the initial collapsed state
        handleResize();

        // Attach the event listener to handle window resize
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Emp

    const closed = true;

    return !(locale == "en" || locale == "fr") ? (
        <div></div>
    ) : (
        <div
            className="flex flex-col sticky top-0 z-50 h-screen text-left text-black rounded-r-lg bg-white text-base hidden md:flex font-semibold"
            style={{ background: bgColor }}
        >
            <IntlProvider
                locale={locale}
                defaultLocale="en"
                messages={messages[locale]}
            >
                <Sidebar
                    collapsed={closed}
                    rootStyles={{
                        [`.${sidebarClasses.container}`]: {
                            backgroundColor: bgColor,

                            borderTopRightRadius: "7px",
                        },
                    }}
                    style={{ borderRight: "#ffffff" }}
                >
                    {" "}
                    <div className="pl-2 pt-10 pr-2 flex flex-col items-center h-100 mt-10">
                        {!collapsed && (
                            <div>
                                <img src={layout.logo_url} width="200"></img>
                            </div>
                        )}
                        {collapsed && (
                            <div
                                className="flex flex-col items-center"
                                onClick={() => navigate("/profile")}
                            >
                                <div className="w-100 h- overflow-hidden">
                                    <img
                                        src={layout.logo_url}
                                        height="50"
                                        className=""
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <Menu
                        menuItemStyles={{
                            button: {
                                [`&.active`]: {
                                    backgroundColor: "#ffffff",
                                    color: "#666666",
                                },
                                "&:hover": {
                                    backgroundColor: hoverColor,
                                },
                            },
                        }}
                        className="pt-10"
                    >
                        <MenuItem
                            icon={<FiHome />}
                            component={<Link to="/home" />}
                        >
                            {" "}
                            <FormattedMessage
                                id="navigator.home"
                                values={{ number: 1 }}
                            />
                        </MenuItem>

                        {user.is_route_module && (
                            <MenuItem
                                icon={<FiCalendar />}
                                component={<Link to="/routing" />}
                            >
                                <div className="flex flex-row items-center ">
                                    <FormattedMessage
                                        id="navigator.myDay"
                                        values={{ number: 1 }}
                                    />
                                </div>
                            </MenuItem>
                        )}

                        <MenuItem
                            icon={<FiUsers />}
                            component={<Link to="/clients" />}
                        >
                            <div className="flex flex-row items-center ">
                                <FormattedMessage
                                    id="navigator.Clients"
                                    values={{ number: 1 }}
                                />
                            </div>
                        </MenuItem>

                        {user.is_lead_module && (
                            <MenuItem
                                icon={<FiUserPlus />}
                                component={<Link to="/leads" />}
                            >
                                <div className="flex flex-row items-center ">
                                    <FormattedMessage
                                        id="navigator.Leads"
                                        values={{ number: 1 }}
                                    />
                                    {leadNotification != 0 && (
                                        <div className="bg-notification rounded-full ml-5 font-semibold"></div>
                                    )}
                                </div>
                            </MenuItem>
                        )}

                        <SubMenu
                            defaultOpen
                            icon={<CgPerformance />}
                            label={
                                <FormattedMessage
                                    id="navigator.Stats"
                                    defaultMessage="Tableaux de bord"
                                />
                            }
                            rootStyles={{
                                ["& > ." + menuClasses.button]: {
                                    backgroundColor: color,
                                    color: "#9f0099",
                                    "&:hover": {
                                        backgroundColor: "#eecef9",
                                    },
                                },
                                ["." + menuClasses.subMenuContent]: {
                                    backgroundColor: color,
                                },
                            }}
                        >
                            {user.is_manager && (
                                <MenuItem
                                    component={<Link to="/dashboard" />}
                                    icon={<VscBlank />}
                                >
                                    <FormattedMessage
                                        id="navigator.Management"
                                        values={{ number: 1 }}
                                    />
                                </MenuItem>
                            )}

                            <MenuItem
                                component={<Link to="/profile" />}
                                icon={<VscBlank />}
                            >
                                <FormattedMessage
                                    id="navigator.Individual"
                                    values={{ number: 1 }}
                                />
                            </MenuItem>
                        </SubMenu>

                        <MenuItem
                            icon={<FiBook />}
                            component={<Link to="/products" />}
                        >
                            <FormattedMessage
                                id="navigator.Product"
                                values={{ number: 1 }}
                            />
                        </MenuItem>
                        <MenuItem
                            icon={<FiMessageSquare />}
                            component={<Link to="/chat" />}
                        >
                            <FormattedMessage
                                id="navigator.chat"
                                values={{ number: 1 }}
                            />
                        </MenuItem>

                        {user.is_training_module && (
                            <MenuItem
                                icon={<PiGraduationCapBold />}
                                component={<Link to="/training" />}
                            >
                                <FormattedMessage
                                    id="navigator.Training"
                                    values={{ number: 1 }}
                                />
                            </MenuItem>
                        )}
                    </Menu>
                </Sidebar>
                <div className="flex-grow"></div>
                <Sidebar
                    collapsed={closed}
                    rootStyles={{
                        [`.${sidebarClasses.container}`]: {
                            backgroundColor: bgColor,
                            borderBottomRightRadius: "7px",
                            borderRight: "0px",
                        },
                    }}
                    style={{ borderRight: "#ffffff" }}
                >
                    {" "}
                    <Menu
                        menuItemStyles={{
                            button: {
                                [`&.active`]: {
                                    backgroundColor: "#ffffff",
                                    color: color,
                                },
                                "&:hover": {
                                    backgroundColor: "#666666",
                                },
                            },
                        }}
                        className="pt-10"
                    >
                        <MenuItem
                            icon={<FiTool />}
                            component={<Link to="/settings" />}
                        >
                            {" "}
                            <FormattedMessage
                                id="navigator.Settings"
                                values={{ number: 1 }}
                            />
                        </MenuItem>
                        <MenuItem
                            icon={<FiLogOut />}
                            onClick={() => logout_auth0()}
                        >
                            {" "}
                            <FormattedMessage
                                id="navigator.Logout"
                                values={{ number: 1 }}
                            />
                        </MenuItem>
                    </Menu>
                </Sidebar>
            </IntlProvider>
        </div>
    );
}

export default Navigator;

/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid"; // Import UUID generator

import FastAPIClient from "../../../client";
import config from "../../../config";
const client = new FastAPIClient(config);

import { FormattedMessage, IntlProvider } from "react-intl";

import { FiCalendar } from "react-icons/fi";

import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    fr: messages_fr,
};

const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
};

// Function to add minutes to a Date object
const addMinutes = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000);
};

const CreateMeetingModal = ({
    closeModal,
    initialEventData = {},
    locale,
    onCreateMeeting,
    onUpdateMeeting,
    user_id,
    isEditing,
    client_id = null,
}) => {
    const [eventData, setEventData] = useState({
        meeting_id: uuidv4(),
        start_date: initialEventData.start
            ? formatDate(initialEventData.start)
            : null,
        end_date: initialEventData.end
            ? isEditing
                ? formatDate(initialEventData.end)
                : formatDate(addMinutes(new Date(initialEventData.start), 30))
            : null,
        user_id: user_id, // Replace with the user's ID if applicable
        meeting_type_id: initialEventData.meeting_type_id
            ? initialEventData.meeting_type_id
            : "1", // by default, s
        is_visited: false, // Set to `true` if needed for your logic
        client_id: false, // Client ID; replace as per your application's data
        attendees: initialEventData.attendees || [], // Ensure this defaults to an empty array
        created_at: initialEventData.created_at
            ? formatDate(new Date(initialEventData.created_at))
            : formatDate(new Date()), // Default to current system time
        updated_at: initialEventData.updated_at
            ? formatDate(new Date(initialEventData.updated_at))
            : formatDate(new Date()), // Default to current system time
        client_alert: initialEventData.client_alert ? initialEventData.client_alert : false
    });

    const [clients, setClients] = useState([]);
    const [meetingTypes, setMeetingTypes] = useState([]);
    const [suggestions, setSuggestions] = useState([]); // Suggestions list
    const [inputValue, setInputValue] = useState(initialEventData.title || ""); // Initialize with title
    const [attendees, setAttendees] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const stateKey =
            name === "start"
                ? "start_date"
                : name === "end"
                ? "end_date"
                : name;
        setEventData((prevData) => ({ ...prevData, [stateKey]: value }));
    };

    useEffect(() => {
        fetchUserClients(user_id, client_id);
        fetchMeetingTypes();
        fetchAttendees();
    }, []);

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const fetchUserClients = (user_id, client_id) => {
        client.getUserClients(user_id, client_id).then((data) => {
            const sortedResults = data.sort((a, b) => {
                // Move objects with 'alert' as true to the beginning
                return b.alert - a.alert; // b.alert - a.alert will move true values to the top
            });
            setClients(sortedResults);
        });
    };

    const fetchMeetingTypes = () => {
        client.getMeetingTypes().then((data) => {
            setMeetingTypes(data?.data.results || []);
        });
    };
    const fetchAttendees = () => {
        client.getUsers().then((data) => {
            if (isEditing) {
                // Don't filter out existing attendees, just filter out the current user
                const meetingAttendees = data?.results.filter(
                    (user) => user.user_id !== user_id
                );
                setAttendees(meetingAttendees || []);
            } else {
                // Filter out the current user before setting the attendees
                const filteredAttendees = data?.results.filter(
                    (user) => user.user_id !== user_id
                );
                setAttendees(filteredAttendees || []);
            }
        });
    };

    const handleSave = async () => {
        const payload = {
            meeting_id: isEditing
                ? initialEventData.meeting_id
                : eventData.meeting_id,
            user_id: eventData.user_id,
            meeting_type_id: eventData.meeting_type_id,
            start_date: eventData.start_date,
            end_date: eventData.end_date,
            is_visited: eventData.is_visited,
            client_id: isEditing
                ? initialEventData.client_id
                : eventData.client_id,
            created_at: isEditing
                ? initialEventData.created_at
                : eventData.created_at,
            updated_at: eventData.updated_at,
            attendees: [user_id, ...eventData.attendees],
            client_alert : eventData.client_alert
        };
console.log(payload)
        try {
            if (isEditing) {
                await client.updateMeeting(
                    initialEventData.meeting_id,
                    payload
                );

                setSuccess("Meeting updated successfully!");
                if (onUpdateMeeting) {
                    onUpdateMeeting({
                        ...payload,
                        client_name: initialEventData.title, // Add client name
                        title: initialEventData.title, // Add title
                        client: {
                            client_name: initialEventData.title,
                            client_id: initialEventData.client_id,
                            alert: initialEventData.client_alert,
                        },
                    });
                }
            } else {
                await client.createMeeting(payload);

                setSuccess("Meeting created successfully!");
                if (onCreateMeeting) onCreateMeeting(eventData);
            }

            setTimeout(() => {
                setSuccess(null);
            }, 5000);

            closeModal();
            alert(`Meeting successfully ${isEditing ? "updated" : "created"}!`);
        } catch (error) {
            console.error(
                `Failed to ${isEditing ? "update" : "create"} meeting:`,
                error
            );
            setError(
                `There was a problem ${
                    isEditing ? "updating" : "creating"
                } the meeting.`
            );
            setTimeout(() => {
                setError(null);
            }, 5000);
        }
    };

    // Function to handle input change and show suggestions
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        setSuggestions(
            clients.filter((client) =>
                client.client_name.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    // Function to handle selecting a suggestion
    const handleSelectSuggestion = (client) => {
        setEventData((prevData) => ({
            ...prevData,
            client_id: client.client_id,
            client_name: client.client_name,
        }));
        setInputValue(client.client_name);
        setSuggestions([]); // Hide suggestions after selection
    };

    const addAttendee = (attendeeId) => {
        setEventData((prevData) => {
            if (!prevData.attendees.includes(attendeeId)) {
                return {
                    ...prevData,
                    attendees: [...prevData.attendees, attendeeId],
                };
            }
            return prevData;
        });
    };

    useEffect(() => {
        console.log("Initial attendees:", initialEventData.attendees);
        console.log("Event data attendees:", eventData.attendees);
    }, [eventData.attendees]);

    const removeAttendee = (attendeeId) => {
        setEventData((prevData) => ({
            ...prevData,
            attendees: prevData.attendees.filter((id) => id !== attendeeId),
        }));
    };

    return (
        <IntlProvider
            locale={locale}
            defaultLocale="en"
            messages={messages[locale]}
        >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-6 rounded-md shadow-md w-1/2">
                    <div className="flex flex-row w-full items-center mb-4">
                        <div className="flex w-10 h-10 bg-gray-100 ml-2 rounded-full items-center justify-center">
                            <FiCalendar />
                        </div>
                        <div>
                            <h2 className="text-xl ml-4">
                                <FormattedMessage
                                    id={
                                        isEditing
                                            ? "editMeetingModal.title"
                                            : "createMeetingModal.title"
                                    }
                                />
                            </h2>
                        </div>
                    </div>
                    {error && (
                        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-red-500 text-white px-4 py-2 rounded">
                            {error}
                        </div>
                    )}

                    {success && (
                        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-4 py-2 rounded">
                            {success}
                        </div>
                    )}

                    <form>
                        <div className="flex items-center mb-2">
                            <label className="w-1/3 pr-2">
                                <FormattedMessage id="createMeetingModal.clientLabel" />
                            </label>
                            {isEditing ? (
                                <div>{initialEventData.client_name}</div>
                            ) : (
                                <>
                                    <input
                                        type="text"
                                        name="title"
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        className="border rounded-full w-full p-2"
                                    />
                                    {suggestions.length > 0 && (
                                        <ul className="top-12 left-0 w-full bg-white border rounded-md shadow-lg z-10 max-h-60 overflow-y-auto">
                                            {suggestions.map((client) => (
                                                <li
                                                    key={client.client_id}
                                                    onClick={() =>
                                                        handleSelectSuggestion(
                                                            client
                                                        )
                                                    }
                                                    className="cursor-pointer p-2 hover:bg-gray-100"
                                                >
                                                    {client.client_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="flex items-center mb-2">
                            <label className="w-1/3 pr-2">
                                <FormattedMessage id="createMeetingModal.startLabel" />
                            </label>
                            <input
                                type="datetime-local"
                                name="start_date"
                                value={eventData.start_date}
                                onChange={handleChange}
                                placeholder={
                                    <FormattedMessage id="createMeetingModal.selectStartTimePlaceholder" />
                                }
                                className="border rounded-full w-full p-2"
                            />
                        </div>
                        <div className="flex items-center mb-2">
                            <label className="w-1/3 pr-2">
                                <FormattedMessage id="createMeetingModal.endLabel" />
                            </label>
                            <input
                                type="datetime-local"
                                name="end_date"
                                value={eventData.end_date}
                                onChange={handleChange}
                                placeholder={
                                    <FormattedMessage id="createMeetingModal.selectEndTimePlaceholder" />
                                }
                                className="border rounded-full w-full p-2"
                            />
                        </div>
                        <div className="flex items-center mb-2">
                            <label className="w-1/3 pr-2">
                                <FormattedMessage id="createMeetingModal.typeLabel" />
                            </label>
                            <select
                                name="meeting_type_id"
                                value={eventData.meeting_type_id}
                                onChange={handleChange}
                                className="border rounded-full w-full p-2"
                            >
                                {meetingTypes.map((type) => (
                                    <option
                                        key={type.meeting_type_id}
                                        value={type.meeting_type_id}
                                    >
                                        {type.description}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex items-center mb-2">
                            <label className="w-1/3 pr-2">
                                <FormattedMessage id="createMeetingModal.attendeesLabel" />
                            </label>
                            <select
                                name="attendees"
                                value={eventData.attendees}
                                onChange={(e) => addAttendee(e.target.value)}
                                className="border rounded-md w-full p-2"
                                multiple
                            >
                                {attendees.map((attendee) => (
                                    <option
                                        key={attendee.user_id}
                                        value={attendee.user_id}
                                    >
                                        {attendee.first_name}{" "}
                                        {attendee.last_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex items-center mb-2">
                            <label className="w-1/3 pr-2">
                                <FormattedMessage id="createMeetingModal.selectedAttendeesLabel" />
                            </label>
                            <ul className="border rounded-full w-full p-2">
                                {eventData.attendees
                                    .filter(
                                        (attendeeId) => attendeeId !== user_id
                                    ) // Exclude current user_id
                                    .map((attendeeId) => {
                                        const attendee = attendees.find(
                                            (a) => a.user_id === attendeeId
                                        );

                                        if (!attendee) return null;
                                        return (
                                            <li
                                                key={attendeeId}
                                                className="mb-2 rounded-full bg-gray-100 flex justify-center items-center px-5"
                                            >
                                                {attendee.first_name}{" "}
                                                {attendee.last_name}
                                                <button
                                                    onClick={() =>
                                                        removeAttendee(
                                                            attendeeId
                                                        )
                                                    }
                                                    className="ml-2 bg-red-400 text-white text-xxs w-4 h-4 rounded-full text-sm font-bold flex items-center justify-center leading-none"
                                                >
                                                    X
                                                </button>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </form>

                    <div className="flex justify-end mt-4">
                        <button
                            onClick={handleSave}
                            className="bg-black text-white px-4 py-2 rounded-full mr-2"
                        >
                            <FormattedMessage
                                id={
                                    isEditing
                                        ? "editMeetingModal.saveButton"
                                        : "createMeetingModal.saveButton"
                                }
                            />
                        </button>
                        <button
                            onClick={closeModal}
                            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-full"
                        >
                            <FormattedMessage id="createMeetingModal.cancelButton" />
                        </button>
                    </div>
                </div>
            </div>
        </IntlProvider>
    );
};

export default CreateMeetingModal;

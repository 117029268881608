import React from "react";
// import { Link } from "react-router-dom";

import { FormattedMessage, IntlProvider } from "react-intl";
import { useNavigate } from "react-router-dom";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const ClientNew = ({ client, alert, type, language }) => {
    // const [locale, setLocale] = useState();

    // useEffect(() => {
    //     // setRefreshing(false);
    //     setLocale(localStorage.getItem("language"));
    // }, []);

    const nav = useNavigate();

    var alert_color = [];
    if (type == "Medium") {
        alert_color = "#C3FF36";
    } else {
        alert_color = "#C3FF36";
    }
    return (
        client && (
            <>
                {" "}
                <IntlProvider locale={language} messages={messages[language]}>
                    {/* <Link to={`/client?client_id=${client.id}`} key={client.id}> */}
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        className="flex w-full md:w-full flex-row items-center justify-between transform bg-white rounded mb-10 border border-gray-200 hover:bg-gray-100"
                    >
                        <div className="w-2/3 xl:w-4/5 md:w-4/5 sm:w-4/5 flex flex-row items-center">
                            <div className="relative float-left p-2 md:p-4 md:ml-10 flex-row items-center w-4/5">
                                <h2 className="flex md:mb-2 tracking-widest text-sm text-black uppercase">
                                    {client?.client_name}
                                </h2>
                                <div className="flex flex-row space-x-2">
                                    <h2 className="flex tracking-widest text-xs text-gray-500 uppercase">
                                        ID: {client?.client_id}
                                    </h2>
                                    <h2 className="flex tracking-widest text-xs text-gray-500 uppercase">
                                        Dernière interaction: 04.11.2024
                                        {client?.derniere_visite}
                                    </h2>
                                </div>
                            </div>
                            <div className="flex w-2/5 md:1/5">
                                <h2
                                    className="items-center font-semibold tracking-wide text-center"
                                    style={{ color: "#E0115F" }}
                                >
                                    {alert && (
                                        <span
                                            className="inline-block ml-2 py-1 px-2 text-black text-xxs rounded-full w-full"
                                            style={{
                                                backgroundColor: alert_color,
                                                color: "black",
                                            }}
                                            data-config-id="change2"
                                        >
                                            <FormattedMessage
                                                id="client.new_reco"
                                                values={{ number: 1 }}
                                            />
                                        </span>
                                    )}
                                </h2>
                            </div>
                            <div className="relative p-4 md:w-1/5"></div>
                        </div>
                        {/* <div className="w-full xl:w-1/5 md:w-1/5 sm:w-1/5 flex flex-col items-center">

                        </div> */}
                        <div className="w-1/3 xl:w-1/5 md:w-1/5 sm:w-1/5 float-left flex flex-col items-center md:mb-0 md:block">
                            <div className="relative flex flex-col items-center">
                                <div className="flex flex-col md:flex-row items-center">
                                    <a
                                        onClick={() =>
                                            nav(`/client/${client?.client_id}`)
                                        }
                                        className="transition text-xxs md:text-sm font-semibold hover:text-black  hover:bg-white bg-black border border-black cursor-pointer bg-black px-4 py-2 mx-auto rounded-full"
                                    >
                                        <FormattedMessage
                                            id="client.details"
                                            values={{ number: 1 }}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </Link> */}
                </IntlProvider>
            </>
        )
    );
};

export default ClientNew;

import React from "react";

import "react-icons/fi";
import { FiLogOut, FiUser } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import FastAPIClient from "../../client";
import config from "../../config";
const client = new FastAPIClient(config);

function Header() {
    const navigate = useNavigate();

    const handleLogout = () => {
        client.logout();

        navigate("/");
    };

    return (
        <header
            style={{ flexShrink: 0, width: "100%" }}
            className="text-center text-black z-50 bg-white sticky md:hidden"
        >
            <div className="flex flex-row w-full items-center pt-5 pb-5 mt-10">
                <div
                    className="flex flex-col items-center w-1/4"
                    onClick={() => navigate("/profile")}
                >
                    <div className="w-35 h-35 overflow-hidden rounded-full p-3 bg-gray-100 text-lg">
                        <FiUser />
                    </div>
                </div>
                <div className="flex flex-col items-center w-1/2"></div>
                <div
                    className="flex flex-col items-center w-1/4"
                    onClick={() => handleLogout()}
                >
                    <div className="w-35 h-35 text-black">
                        <FiLogOut />
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;

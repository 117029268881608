import React from "react";

import "./shapedivider.css";
import { useAuth0 } from "@auth0/auth0-react";

import { isPlatform } from "@ionic/react";

const iosOrAndroid = isPlatform("hybrid");

const Login = () => {
    const { getAccessTokenSilently } = useAuth0();

    const login_auth0 = async () => {
        loginWithRedirect();
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: `https://www.api.myway.technology`,
                scope: "openid profile email read:appointments offline_access",
            },
        });
        localStorage.setItem("token_test", accessToken);
    };

    const { loginWithRedirect } = useAuth0();

    return (
        <>
            <section className="bg-white ">
                <div className="flex flex-row w-full items-center justify-center">
                    <div className="flex flex-col items-center justify-center min-h-screen bg-white text-left w-full xl:w-3/6 md:w-3/6 sm:w-full">
                        <div className="w-full max-w-xs m-auto bg-white rounded p-5 shadow-lg">
                            <header>
                                <img
                                    src={require("./MYWAY_Logo-Noir.png")}
                                    width="120"
                                    className="ml-20 mb-10 mt-10"
                                ></img>
                            </header>

                            {!iosOrAndroid && (
                                <button
                                    className="rounded-md bg-black text-white w-full font-semibold p-2"
                                    onClick={() => login_auth0()}
                                >
                                    Login
                                </button>
                            )}
                        </div>
                        <h1 className="text-xxs mb-5">
                            {" "}
                            © 2024 MYWAY Technology SAS, All rights reserved.
                        </h1>
                    </div>

                    <div className="flex flex-col items-center justify-center min-h-screen text-left w-0 invisible xl:w-3/6 md:w-3/6 sm:w-0 md:visible text-background">
                        <h1 className="text-xxl font-bold text-gold ml-10 mr-40">
                            EMPOWER YOUR SALES REPS AND PROVIDE OUTSTANDING
                            CUSTOMER EXPERIENCES.
                        </h1>
                        <h1 className="text-white text-xl ml-10 mr-40 mt-10">
                            Grow your clients&apos; lifetime value, their
                            satisfaction, your revenue, operating margin, team
                            productivity and engagement with MYWAY.
                        </h1>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Login;

/*eslint-disable*/

import React, { useEffect, useRef, useState } from "react";

import { FormattedMessage, IntlProvider } from "react-intl";

import { DndProvider, useDrag, useDrop } from "react-dnd";

import { Link } from "react-router-dom";

import FastAPIClient from "../../../client";
import fast_api_config from "../../../config";

// Instantiate the FastAPI client
const client = new FastAPIClient(fast_api_config);

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

import { FiTrash2 } from "react-icons/fi";

const ItemTypes = {
    CARD: "card",
};

const DraggableMeetingList = ({
    id,
    text,
    time,
    index,
    meeting_id,
    alert,
    is_visited,
    moveCard,
    isLastCard,
    handleDeleteMeeting,
    meeting_type,
    selectedDraggable,
    setSelectedDraggable
}) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => ({ id, index }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    const [locale, setLocale] = useState();

    useEffect(() => {
        // setRefreshing(false);
        setLocale(localStorage.getItem("language"));
    }, []);

    const handleDeleteEvent = async (eventId) => {
        try {
            await client.deleteMeeting(eventId); // Call the API to delete the meeting
            handleDeleteMeeting(eventId); // Update the local state
        } catch (error) {
            console.error("Failed to delete meeting:", error);
        }
    };

    const handleClick = () => {
        setSelectedDraggable(meeting_id)
    }

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <div
                ref={ref}
                style={{
                    borderColor: "#f3f4f6",

                    backgroundColor: `${meeting_id === selectedDraggable ? "white" : "white"}`,
                    cursor: "move",
                    opacity,
                }}
                className="w-full"
                onClick={()=> handleClick(meeting_id)}
            >
                <div className="flex flex-row p-2">
                    <div
                        className={`w-full ${
                            isLastCard ? "" : "border-b-2 border-gray-200"
                        }`}
                    >
                        <div className="flex flex-row items-center p-2 mb-2">
                            <div className="flex flex-col w-2/3 text-left">
                                <div className="flex flex-row">
                                    <div className="mb-2">{text} </div>
                                    {alert && (
                                        <div className="flex ml-2 flex-row items-center bg-gold rounded-full text-black text-xxxs font-bold text-center mb-2 pl-1 pr-1 whitespace-nowrap flex-shrink-0 h-[25px]">
                                            <div className="">
                                                {" "}
                                                <FormattedMessage
                                                    id="routing.new_reco"
                                                    values={{ number: 1 }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="w-2/3 text-gray-400 text-sm text-left mb-2">
                                    {time.start} - {time.end}
                                </div>
                            </div>
                            <div className="flex flex-col w-1/3 left">
                                <div className="flex flex-row w-full">
                                    <div className="w-5/6"></div>
                                    <div className="w-1/6 ">
                                        {!is_visited && (
                                            <button
                                                onClick={() =>
                                                    handleDeleteEvent(
                                                        meeting_id
                                                    )
                                                }
                                                className="text-black hover:text-red-700"
                                            >
                                                <FiTrash2 />
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="left mb-4"> </div>
                                <div className="left mt-5">
                                    {" "}
                                    <Link
                                        to={`/client/${id}?meeting_id=${meeting_id}&meeting_type_id=${meeting_type}`}
                                        key={id}
                                    >
                                        {is_visited ? (
                                            <a className="text-xs text-white border border-gray-400 font-semibold bg-gray-400 cursor-pointer hover:bg-buttonMainHoverBackground hover:text-buttonMainHoverFontColor px-3 py-2 mx-auto mt-3 rounded-full">
                                                {" "}
                                                <FormattedMessage
                                                    id="routing.start_visit"
                                                    values={{ number: 1 }}
                                                />
                                            </a>
                                        ) : (
                                            <a className="text-xs text-white border border-black font-semibold bg-black cursor-pointer hover:bg-buttonMainHoverBackground hover:text-buttonMainHoverFontColor px-3 py-2 mx-auto mt-3 rounded-full">
                                                <FormattedMessage
                                                    id="routing.start_visit"
                                                    values={{ number: 1 }}
                                                />
                                            </a>
                                        )}
                                    </Link>
                                </div>
                            </div>{" "}
                        </div>
                    </div>
                </div>
            </div>
        </IntlProvider>
    );
};

export default DraggableMeetingList;

import React, { useState, useEffect } from "react";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";

import { FiInfo, FiCheckSquare } from "react-icons/fi";

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    BarElement,
    ArcElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "top",
        },
        title: {
            display: false,
            text: "Chart.js Bar Chart",
        },
    },
};

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";
import SmallStatisticsBox from "../../components/Charts/SmallStatisticsBox";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const ManagementDashboard = () => {
    // if (refreshing) return !isLoggedIn ? <NotLoggedIn /> : <Loader />;

    const [locale, setLocale] = useState();

    useEffect(() => {
        // setRefreshing(false);
        setLocale(localStorage.getItem("language"));
    }, []);

    const labels_fr = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
    ];
    const labels_en = [
        "January",
        "Februar",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const labels =
        locale === "en" ? labels_en : locale === "fr" ? labels_fr : [];

    // Function to generate gradient colors between white and black
    // Function to generate a gradient between two colors
    function generateGradientColors(startColor, endColor, numColors) {
        const start = {
            r: parseInt(startColor.slice(1, 3), 16),
            g: parseInt(startColor.slice(3, 5), 16),
            b: parseInt(startColor.slice(5, 7), 16),
        };
        const end = {
            r: parseInt(endColor.slice(1, 3), 16),
            g: parseInt(endColor.slice(3, 5), 16),
            b: parseInt(endColor.slice(5, 7), 16),
        };

        const colors = [];
        for (let i = 0; i < numColors; i++) {
            const ratio = i / (numColors - 1); // Calculate ratio between 0 and 1
            const r = Math.round(start.r + ratio * (end.r - start.r));
            const g = Math.round(start.g + ratio * (end.g - start.g));
            const b = Math.round(start.b + ratio * (end.b - start.b));
            colors.push(`rgb(${r}, ${g}, ${b})`);
        }
        return colors;
    }

    // Update doughnut data dynamically based on labels length
    const doughnut_data = {
        labels: ["Restaurant", "Café", "Bar", "Discotheque"], // Add labels as needed
        datasets: [
            {
                data: [300, 50, 100, 200], // Corresponding data values
                backgroundColor: generateGradientColors(
                    "#2D3F04",
                    "#C3FF36",
                    ["Restaurant", "Café", "Bar", "Discotheque"].length
                ), // Generate colors based on label count
            },
        ],
    };

    const doughnut_options = {
        plugins: {
            legend: {
                display: true,
                position: "right",
                text: "Client type",
            },
        },
        elements: {
            arc: {
                borderWidth: 0,
            },
        },
        cutout: "70%", // Adjust this percentage to make the arcs thinner or thicker
    };

    const stacked_options = {
        plugins: {
            legend: {
                position: "right",
                display: false,
            },
            title: {
                display: false,
                text: "Chart.js Bar Chart - Stacked",
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: true, // Show horizontal grid lines
                    color: "rgba(0, 0, 0, 0.1)", // Light gray color for subtle lines
                    lineWidth: 1, // Customize line thickness
                    borderDash: [5, 5], // Optional: dashed lines for a lighter look
                },
            },
        },
        maintainAspectRatio: false, // Allow control over width and height
    };

    const stacked_data2 = {
        labels,
        datasets: [
            {
                // label: beer,
                data: [34, 45, 23, 45, 67, 89, 34, 45, 23, 45, 67, 45], // Example data
                backgroundColor: "#000000",
                borderRadius: 30,
                barThickness: 20, // Set to a smaller value for thinner bars
            },
        ],
    };

    const nba_result_data = {
        labels: ["En attente", "Acceptée", "Refusé"],
        datasets: [
            {
                label: "beer",
                data: [450, 700, 250], // Example data
                backgroundColor: ["#000000", "#C3FF36", "#FF365E"], // Colors for each label
                borderRadius: 30,
                barThickness: 20, // Set to a smaller value for thinner bars
            },
        ],
    };

    const nba_type_data = {
        labels: [
            "Prévention départ des clients",
            "Recommandation produit",
            "Gestion des relations",
            "Optimisation du cycle de commande",
        ],
        datasets: [
            {
                label: "beer",
                data: [700, 450, 250, 200], // Example data
                backgroundColor: ["#000000", "#000000", "#000000", "#000000"], // Colors for each label
                borderRadius: 30,
                barThickness: 20, // Set to a smaller value for thinner bars
            },
        ],
    };

    return (
        <>
            <IntlProvider locale={locale} messages={messages[locale]}>
                <div className="mt-10 ml-5 md:mt-20 text-left md:ml-20 flex flex-row items-center">
                    <h1 className="mb-12 text-xl text-black mt-5">
                        <FormattedMessage
                            id="performance.my_statistics"
                            values={{ number: 1 }}
                        />
                    </h1>
                    <h1 className="mb-12 text-sm text-gray-400 mt-6 ml-5 ">
                        <FormattedMessage
                            id="performance.last_updated"
                            values={{ number: 1 }}
                        />
                        13, 2024
                    </h1>
                </div>
                <div className="flex flex-col ml-5 mr-5 md:mr-0 md:ml-0 md:flex-row pb-6 mb-20 text-black rounded-3xl">
                    <div className=" container flex flex-col bg-white rounded-3xl md:ml-10 mr-10 w-full">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-7 gap-2 align-start md:ml-10">
                            <SmallStatisticsBox
                                name={"Chiffre d'affaires"}
                                value_current_year={"11 000€"}
                                change={"+10%"}
                                value_previous_year={"10 000€"}
                                period={"annual"}
                            />
                            <SmallStatisticsBox
                                name={"Volume"}
                                value_current_year={"16 000"}
                                change={"+10%"}
                                value_previous_year={"14 000"}
                                period={"annual"}
                            />
                            <SmallStatisticsBox
                                name={"Valeur du panier"}
                                value_current_year={"450€"}
                                change={"+10%"}
                                value_previous_year={"400€"}
                                period={"annual"}
                            />
                            <SmallStatisticsBox
                                name={"Taux de départ"}
                                value_current_year={"15%"}
                                change={"-10%"}
                                value_previous_year={"17%"}
                                period={"annual"}
                            />
                            <SmallStatisticsBox
                                name={"Nombre de clients"}
                                value_current_year={"49"}
                                change={"+10%"}
                                value_previous_year={"44"}
                                period={"annual"}
                            />
                            <SmallStatisticsBox
                                name={"Taux de couverture"}
                                value_current_year={"95%"}
                                change={"+5%"}
                                value_previous_year={"90%"}
                                period={"annual"}
                            />
                            <SmallStatisticsBox
                                name={"Balance AG"}
                                value_current_year={"10 000€"}
                                change={""}
                                value_previous_year={""}
                            />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-2 align-start md:ml-10">
                            <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                                <h3 className="text-black text-lg">
                                    <FormattedMessage
                                        id="performance.sales_by_product_type"
                                        values={{ number: 1 }}
                                    />
                                </h3>
                                <div
                                    style={{
                                        minHeight: 250,
                                        maxWidth: 650,
                                    }}
                                    className="mb-5 mt-5"
                                >
                                    <Bar
                                        options={stacked_options}
                                        data={stacked_data2}
                                    />
                                </div>
                            </div>
                            <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                                <h3 className="text-black text-lg">Volume</h3>
                                <div
                                    style={{
                                        minHeight: 250,
                                        maxWidth: 650,
                                    }}
                                    className="mb-5 mt-5"
                                >
                                    <Bar
                                        options={stacked_options}
                                        data={stacked_data2}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-2 align-start md:ml-10 mt-4">
                            <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                                <h3 className="text-black text-lg ">
                                    <FormattedMessage
                                        id="performance.client_by_type"
                                        values={{ number: 1 }}
                                    />
                                </h3>
                                <div
                                    className="flex justify-center items-center"
                                    style={{ maxWidth: 300, margin: "0 auto" }}
                                >
                                    <Doughnut
                                        options={doughnut_options}
                                        data={doughnut_data}
                                    />
                                </div>
                            </div>
                            <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                                <h3 className="text-black text-lg">
                                    Répartition par catégorie produits
                                </h3>
                                <div
                                    className="flex justify-center items-center"
                                    style={{ maxWidth: 300, margin: "0 auto" }}
                                >
                                    <Doughnut
                                        options={doughnut_options}
                                        data={doughnut_data}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-2 align-start md:ml-10 mt-4">
                            <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                                <h3 className="text-black text-lg ">
                                    Aperçu des next best actions exécutées
                                </h3>
                                <div
                                    className="mb-5 mt-5"
                                    style={{
                                        minHeight: 250,
                                        maxWidth: 650,
                                    }}
                                >
                                    <Bar
                                        options={stacked_options}
                                        data={nba_result_data}
                                    />
                                </div>
                            </div>
                            <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                                <h3 className="text-black text-lg">
                                    Types de next best actions
                                </h3>
                                <div
                                    className="mb-5 mt-5"
                                    style={{
                                        minHeight: 250,
                                        maxWidth: 650,
                                    }}
                                >
                                    <Bar
                                        options={stacked_options}
                                        data={nba_type_data}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex justify-center mt-4 ml-10 pr-10">
                            <div className="p-4 bg-white flex flex-col justify-center rounded-md border border-gray-200 w-full">
                                <div className="text-left">
                                    <h3 className="text-black text-lg">
                                        Comparaison par groupe client
                                    </h3>
                                </div>
                                <div className="flex justify-center mt-4">
                                    <table className="w-3/4 border-collapse text-sm">
                                        <thead>
                                            {/* Main Column Headers */}
                                            <tr>
                                                <th
                                                    className=" px-4 py-2 text-left"
                                                    colSpan="1"
                                                ></th>
                                                <th
                                                    className="border-b border-r border-gray-300 px-4 py-2 text-center"
                                                    colSpan="2"
                                                >
                                                    Brasserie
                                                </th>
                                                <th
                                                    className="border-b border-r border-gray-300 px-4 py-2 text-center"
                                                    colSpan="2"
                                                >
                                                    Bar
                                                </th>
                                                <th
                                                    className="border-b border-r border-gray-300 px-4 py-2 text-center"
                                                    colSpan="2"
                                                >
                                                    Univers du soir
                                                </th>
                                                <th
                                                    className="border-b border-gray-300 px-4 py-2 text-center"
                                                    colSpan="2"
                                                >
                                                    Restaurant
                                                </th>
                                            </tr>
                                            {/* Year Sub-headers */}
                                            <tr>
                                                <th className="border-b border-gray-300 px-4 py-2 text-left"></th>
                                                <th className="border-b border-gray-300  px-4 py-2 text-center">
                                                    2023
                                                </th>
                                                <th className="border-b border-r border-gray-300 px-4 py-2 text-center">
                                                    2024
                                                </th>
                                                <th className="border-b border-gray-300 px-4 py-2 text-center">
                                                    2023
                                                </th>
                                                <th className="border-b border-r border-gray-300 px-4 py-2 text-center">
                                                    2024
                                                </th>
                                                <th className="border-b border-gray-300 px-4 py-2 text-center">
                                                    2023
                                                </th>
                                                <th className="border-b border-r border-gray-300 px-4 py-2 text-center">
                                                    2024
                                                </th>
                                                <th className="border-b border-gray-300 px-4 py-2 text-center">
                                                    2023
                                                </th>
                                                <th className="border-b border-gray-300 px-4 py-2 text-center">
                                                    2024
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    Nombre de clients
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    532
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    543
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    412
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    423
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    212
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    222
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    143
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    153
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    Nombre de clients à risque
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    47
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    52
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    38
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    37
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    21
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    20
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    10
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    11
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    CA moyen par client (€)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    5000,00€
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    6000,00€
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    4000,00€
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    4500,00€
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    7000,00€
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    7500,00€
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    6500,00€
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    6800,00€
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    Volume vendu (hL)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    5000
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    6000
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    4000
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    4500
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    7000
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    7500
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    6500
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    6800
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    Taux de marge par client (%)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    31%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    32%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    31%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    33%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    29%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    31%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    32%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    31%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    # moyen de catégories
                                                    produits référencées par
                                                    client
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    53
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    64
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    50
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    55
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    62
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    63
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    66
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    69
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    # moyen de références par
                                                    client
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    52
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    63
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    41
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    47
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    71
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    76
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    53
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    55
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    # moyen de catégories
                                                    produits par facture
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    5
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    6
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    4
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    6
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    7
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    8
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    6
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    6
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    # moyen de références par
                                                    facture
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    52
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    63
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    40
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    42
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    71
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    76
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    66
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    69
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="border-b font-bold border-gray-300 px-4 py-2 text-left">
                                                    Catégorie produit la plus
                                                    fréquemment commandée
                                                </td>
                                                <td className="border-b border-gray-300 px-4 py-2 text-center">
                                                    Bière
                                                </td>
                                                <td className="border-b border-r border-gray-300 px-4 py-2 text-center">
                                                    Bière
                                                </td>
                                                <td className="border-b border-gray-300 px-4 py-2 text-center">
                                                    Spiritueux
                                                </td>
                                                <td className="border-b border-r border-gray-300 px-4 py-2 text-center">
                                                    Bière
                                                </td>
                                                <td className="border-b border-gray-300 px-4 py-2 text-center">
                                                    Spiritueux
                                                </td>
                                                <td className="border-b border-r border-gray-300 px-4 py-2 text-center">
                                                    Spiritueux
                                                </td>
                                                <td className="border-b border-gray-300 px-4 py-2 text-center">
                                                    Vins
                                                </td>
                                                <td className="border-b border-gray-300 px-4 py-2 text-center">
                                                    Vins
                                                </td>
                                            </tr>
                                            {/* Add more rows as needed */}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full flex flex-col justify-center mt-4 pr-10">
                                    <h3 className="pb-1 text-black text-lg text-left w-full flex flex-row space-x-3 justify-start items-center">
                                        <FiCheckSquare />
                                        <span>Next best actions</span>
                                    </h3>
                                    <div className="bg-white flex flex-row justify-center  w-full space-x-2 text-left py-5">
                                        <div className="w-1/2 flex rounded-md border border-gray-200 items-center py-3">
                                            <div className="flex h-full w-20 text-xxl items-center justify-center text-gray-500">
                                                <FiInfo />
                                            </div>
                                            <div>
                                                Marge dans les restaurants en
                                                baisse d&apos;1 point.
                                                <br />
                                                Accélérer les ventes
                                                d&apos;articles avec une marge
                                                plus élevée.
                                            </div>
                                        </div>
                                        <div className="p-2 w-1/2 flex rounded-md border border-gray-200 items-center py-5">
                                            <div className="flex h-full w-20 text-xxl items-center justify-center text-gray-500">
                                                <FiInfo />
                                            </div>
                                            <div className="h-full">
                                                Nombre de clients à risque en
                                                hausse dans les brasseries.
                                                <br />
                                                Mener des enquêtes de
                                                satisfaction.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex justify-center mt-4 ml-10 pr-10">
                            <div className="p-4 bg-white flex flex-col justify-center rounded-md border border-gray-200 w-full">
                                <div className="text-left">
                                    <h3 className="text-black text-lg">
                                        Comparaison par famille de produit
                                    </h3>
                                </div>
                                <div className="flex justify-center mt-4">
                                    <table className="w-3/4 border-collapse text-sm">
                                        <thead>
                                            {/* Main Column Headers */}
                                            <tr>
                                                <th
                                                    className=" px-4 py-2 text-left"
                                                    colSpan="1"
                                                ></th>
                                                <th
                                                    className="border-b border-r border-gray-300 px-4 py-2 text-center"
                                                    colSpan="2"
                                                >
                                                    Bière
                                                </th>
                                                <th
                                                    className="border-b border-r border-gray-300 px-4 py-2 text-center"
                                                    colSpan="2"
                                                >
                                                    Spiritueux
                                                </th>
                                                <th
                                                    className="border-b border-r border-gray-300 px-4 py-2 text-center"
                                                    colSpan="2"
                                                >
                                                    Vin
                                                </th>
                                                <th
                                                    className="border-b  border-r border-gray-300 px-4 py-2 text-center"
                                                    colSpan="2"
                                                >
                                                    Champagne
                                                </th>
                                                <th
                                                    className="border-b border-gray-300 px-4 py-2 text-center"
                                                    colSpan="2"
                                                >
                                                    Sans alcool
                                                </th>
                                            </tr>
                                            {/* Year Sub-headers */}
                                            <tr>
                                                <th className="border-b border-gray-300 px-4 py-2 text-left"></th>
                                                <th className="border-b border-gray-300  px-4 py-2 text-center">
                                                    2023
                                                </th>
                                                <th className="border-b border-r border-gray-300 px-4 py-2 text-center">
                                                    2024
                                                </th>
                                                <th className="border-b border-gray-300 px-4 py-2 text-center">
                                                    2023
                                                </th>
                                                <th className="border-b border-r border-gray-300 px-4 py-2 text-center">
                                                    2024
                                                </th>
                                                <th className="border-b border-gray-300 px-4 py-2 text-center">
                                                    2023
                                                </th>
                                                <th className="border-b border-r border-gray-300 px-4 py-2 text-center">
                                                    2024
                                                </th>
                                                <th className="border-b border-gray-300 px-4 py-2 text-center">
                                                    2023
                                                </th>
                                                <th className="border-b border-r border-gray-300 px-4 py-2 text-center">
                                                    2024
                                                </th>
                                                <th className="border-b border-gray-300 px-4 py-2 text-center">
                                                    2023
                                                </th>
                                                <th className="border-b border-gray-300 px-4 py-2 text-center">
                                                    2024
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    CA généré (k€)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    21,1
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    21,2
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    29
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    30,2
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    32
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    32,1
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    25
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    24,3
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    12
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    12,5
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    CA moyen par client (€)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    5000,00€
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    6000,00€
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    4000,00€
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    4500,00€
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    7000,00€
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    7500,00€
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    6500,00€
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    6100,00€
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    4000,00€
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    4100,00€
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    Volume vendu (hL)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    5000
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    6000
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    4000
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    4500
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    7000
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    7500
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    6500
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    6800
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    3200
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    3350
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    Taux de marge par client (%)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    31%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    32%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    27%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    30%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    29%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    30%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    32%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    33%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    24%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    25%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    DN total client (%)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    30%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    31%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    31%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    33%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    29%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    30%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    34%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    31%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    27%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    28%
                                                </td>
                                            </tr>

                                            {/* Add more rows as needed */}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full flex flex-col justify-center mt-4 pr-10">
                                    <h3 className="pb-1 text-black text-lg text-left w-full flex flex-row space-x-3 justify-start items-center">
                                        <FiCheckSquare />
                                        <span>Next best actions</span>
                                    </h3>
                                    <div className="bg-white flex flex-row justify-center  w-full space-x-2 text-left py-5">
                                        <div className="w-1/2 flex rounded-md border border-gray-200 items-center py-3">
                                            <div className="flex h-full w-20 text-xxl items-center justify-center text-gray-500">
                                                <FiInfo />
                                            </div>
                                            <div>
                                                Les ventes de Corona diminuent
                                                en raison de promotions en cours
                                                de la part de concurrents.
                                                <br />
                                                Proposer de lancer une offre
                                                promotionnelle pour accélérer
                                                les ventes.
                                            </div>
                                        </div>
                                        <div className="p-2 w-1/2 flex rounded-md border border-gray-200 items-center py-5">
                                            <div className="flex h-full w-20 text-xxl items-center justify-center text-gray-500">
                                                <FiInfo />
                                            </div>
                                            <div className="h-full">
                                                Les ventes des boissons sans
                                                alcool n&apos;augmentent pas
                                                proportionnellement à celles des
                                                spiritueux.
                                                <br />
                                                Inciter les commerciaux à
                                                associer les deux catégories
                                                produits lors d&apos;une vente.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex justify-center mt-4 ml-10 pr-10">
                            <div className="p-4 bg-white flex flex-col justify-center rounded-md border border-gray-200 w-full">
                                <div className="text-left">
                                    <h3 className="text-black text-lg">
                                        Comparaison par commercial
                                    </h3>
                                </div>
                                <div className="flex justify-center mt-4">
                                    <table className="w-3/4 border-collapse text-sm">
                                        <thead>
                                            {/* Main Column Headers */}
                                            <tr>
                                                <th
                                                    className=" px-4 py-2 text-left"
                                                    colSpan="1"
                                                ></th>
                                                <th
                                                    className="border-b border-r border-gray-300 px-4 py-2 text-center"
                                                    colSpan="2"
                                                >
                                                    Marie
                                                </th>
                                                <th
                                                    className="border-b border-r border-gray-300 px-4 py-2 text-center"
                                                    colSpan="2"
                                                >
                                                    Antoine
                                                </th>
                                                <th
                                                    className="border-b border-r border-gray-300 px-4 py-2 text-center"
                                                    colSpan="2"
                                                >
                                                    Dimitri
                                                </th>
                                                <th
                                                    className="border-b border-gray-300 px-4 py-2 text-center"
                                                    colSpan="2"
                                                >
                                                    Thomas
                                                </th>
                                            </tr>
                                            {/* Year Sub-headers */}
                                            <tr>
                                                <th className="border-b border-gray-300 px-4 py-2 text-left"></th>
                                                <th className="border-b border-gray-300  px-4 py-2 text-center">
                                                    2023
                                                </th>
                                                <th className="border-b border-r border-gray-300 px-4 py-2 text-center">
                                                    2024
                                                </th>
                                                <th className="border-b border-gray-300 px-4 py-2 text-center">
                                                    2023
                                                </th>
                                                <th className="border-b border-r border-gray-300 px-4 py-2 text-center">
                                                    2024
                                                </th>
                                                <th className="border-b border-gray-300 px-4 py-2 text-center">
                                                    2023
                                                </th>
                                                <th className="border-b border-r border-gray-300 px-4 py-2 text-center">
                                                    2024
                                                </th>
                                                <th className="border-b border-gray-300 px-4 py-2 text-center">
                                                    2023
                                                </th>
                                                <th className="border-b border-gray-300 px-4 py-2 text-center">
                                                    2024
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    Nombre de clients
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    132
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    143
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    112
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    123
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    112
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    122
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    143
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    153
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    Taux de couverture client
                                                    (%)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    89%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    94%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    90%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    95%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    89%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    94%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    91%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    96%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    Taux de conversion
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    25%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    30%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    16%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    17%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    18%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    20%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    18%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    15%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    Taux de départ client (%)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    9%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    4%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    10%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    5%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    9%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    4%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    1%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    6%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    CA généré (k€)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    1200€
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    1350€
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    1500€
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    1400€
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    1300€
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    1200€
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    1650€
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    1500€
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    Taux de marge généré (%)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    31%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    32%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    31%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    33%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    29%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    31%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    32%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    31%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    Volume vendu (hL)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    2500
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    2600
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    3100
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    1900
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    1400
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    1800
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    3100
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    2800
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    # moyen de catégories
                                                    produits référencées par
                                                    client
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    52
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    63
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    41
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    47
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    71
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    76
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    66
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    69
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    # moyen de références par
                                                    client
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    52
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    63
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    41
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    47
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    71
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    76
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    66
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    69
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    # moyen de catégories
                                                    produits par facture
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    5
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    6
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    4
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    4
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    7
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    7
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    6
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    6
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    # moyen de références par
                                                    facture
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    52
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    63
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    41
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    47
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    71
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    76
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    66
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    69
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    Catégorie produit la plus
                                                    fréquemment vendue
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    Bière
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    Bière
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    Bière
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    Bière
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    Bière
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    Bière
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    Bière
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    Bière
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    DN Bière (%)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    65%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    67%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    68%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    73%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    70%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    83%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    68%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    71%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    DN Vins (%)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    64%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    81%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    62%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    65%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    73%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    85%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    70%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    76%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    DN Spiritueux (%)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    62%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    67%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    71%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    73%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    74%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    80%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    75%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    77%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    DN Café (%)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    60%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    66%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    76%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    77%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    52%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    60%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    64%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    70%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    DN Softs, Jus, Eaux (%)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    61%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    67%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    72%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    74%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    66%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    72%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    73%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    76%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    DN Autres boissons (%)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    65%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    67%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    69%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    73%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    71%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    81%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    70%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    78%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    DN Épicerie (%)
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    59%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    61%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    80%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    73%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    75%
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    79%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    77%
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    81%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b  font-bold border-gray-300 px-4 py-2 text-left">
                                                    DN Autres non-boissons (%)
                                                </td>
                                                <td className="border-b  border-gray-300 px-4 py-2 text-center">
                                                    65%
                                                </td>
                                                <td className="border-b  border-r border-gray-300 px-4 py-2 text-center">
                                                    67%
                                                </td>
                                                <td className="border-b  border-gray-300 px-4 py-2 text-center">
                                                    71%
                                                </td>
                                                <td className="border-b  border-r border-gray-300 px-4 py-2 text-center">
                                                    73%
                                                </td>
                                                <td className="border-b  border-gray-300 px-4 py-2 text-center">
                                                    78%
                                                </td>
                                                <td className="border-b border-r border-gray-300 px-4 py-2 text-center">
                                                    80%
                                                </td>
                                                <td className="border-b  border-gray-300 px-4 py-2 text-center">
                                                    69%
                                                </td>
                                                <td className="border-b  border-gray-300 px-4 py-2 text-center">
                                                    76%
                                                </td>
                                            </tr>

                                            {/* Add more rows as needed */}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full flex flex-col justify-center mt-4 pr-10">
                                    <h3 className="pb-1 text-black text-lg text-left w-full flex flex-row space-x-3 justify-start items-center">
                                        <FiCheckSquare />
                                        <span>Next best actions</span>
                                    </h3>
                                    <div className="bg-white flex flex-row justify-center  w-full space-x-2 text-left py-5">
                                        <div className="w-1/2 flex rounded-md border border-gray-200 items-center py-3">
                                            <div className="flex h-full w-20 text-xxl items-center justify-center text-gray-500">
                                                <FiInfo />
                                            </div>
                                            <div>
                                                Taux de départ client en hausse
                                                et taux de conversion en baisse
                                                pour Thomas.
                                                <br />
                                                Programmer une formation.
                                            </div>
                                        </div>
                                        <div className="p-2 w-1/2 flex rounded-md border border-gray-200 items-center py-5">
                                            <div className="flex h-full w-20 text-xxl items-center justify-center text-gray-500">
                                                <FiInfo />
                                            </div>
                                            <div className="h-full">
                                                Marie vient d&apos;établir un
                                                nouveau record en convertissant
                                                le plus grand nombre de
                                                prospects en un mois !
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex justify-center mt-4 ml-10 pr-10">
                            <div className="p-4 bg-white flex flex-col justify-center rounded-md border border-gray-200 w-full">
                                <div className="text-left">
                                    <h3 className="text-black text-lg">
                                        Suivi des opérations commerciales
                                    </h3>
                                </div>
                                <div className="flex justify-center mt-4">
                                    <table className="w-3/4 border-collapse text-sm">
                                        <thead>
                                            {/* Main Column Headers */}
                                            <tr>
                                                <th className="border-b border-gray-300 px-4 py-2 text-left"></th>
                                                <th
                                                    className="border-b border-r border-gray-300 px-4 py-2 text-center"
                                                    colSpan="1"
                                                >
                                                    Op 1
                                                </th>
                                                <th
                                                    className="border-b border-r border-gray-300 px-4 py-2 text-center"
                                                    colSpan="1"
                                                >
                                                    Op 2
                                                </th>
                                                <th
                                                    className="border-b border-r border-gray-300 px-4 py-2 text-center"
                                                    colSpan="1"
                                                >
                                                    Op 3
                                                </th>
                                                <th
                                                    className="border-b border-gray-300 px-4 py-2 text-center"
                                                    colSpan="1"
                                                >
                                                    Op 4
                                                </th>
                                            </tr>
                                            {/* Year Sub-headers */}
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    Nombre de ventes
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    132
                                                </td>
                                                <td className="border-b border-dashed  border-r border-gray-300 px-4 py-2 text-center">
                                                    143
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    112
                                                </td>
                                                <td className="border-b border-dashed  border-gray-300 px-4 py-2 text-center">
                                                    123
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    CA généré (€)
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    1200€
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    1350€
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    1500€
                                                </td>
                                                <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                                    1400€
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left">
                                                    Volume vendu (hL)
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    250
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    260
                                                </td>
                                                <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                                    310
                                                </td>
                                                <td className="border-b border-dashed  border-gray-300 px-4 py-2 text-center">
                                                    190
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-b  font-bold border-gray-300 px-4 py-2 text-left">
                                                    DN (%)
                                                </td>
                                                <td className="border-b  border-r border-gray-300 px-4 py-2 text-center">
                                                    25%
                                                </td>
                                                <td className="border-b  border-r border-gray-300 px-4 py-2 text-center">
                                                    31%
                                                </td>
                                                <td className="border-b  border-r border-gray-300 px-4 py-2 text-center">
                                                    19%
                                                </td>
                                                <td className="border-b border-gray-300 px-4 py-2 text-center">
                                                    22%
                                                </td>
                                            </tr>

                                            {/* Add more rows as needed */}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full flex flex-col justify-center mt-4 pr-10">
                                    <h3 className="pb-1 text-black text-lg text-left w-full flex flex-row space-x-3 justify-start items-center">
                                        <FiCheckSquare />
                                        <span>Next best actions</span>
                                    </h3>

                                    <div className="p-2 w-full flex rounded-md border border-gray-200 items-center py-5">
                                        <div className="flex h-full w-20 text-xxl items-center justify-center text-gray-500">
                                            <FiInfo />
                                        </div>
                                        <div className="h-full">
                                            L&apos;Op 3 a moins de succès car
                                            une opération similaire est en cours
                                            chez Métro sur les mêmes produits.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IntlProvider>
        </>
    );
};

export default ManagementDashboard;

import React from "react";

const ToggleButton = ({ isWeekly, onToggle }) => {
    return (
        <div className="bg-gray-200 p-1 rounded-full text-xxs">
            <button
                className={`toggle-button ${
                    isWeekly
                        ? "active bg-gray-200 text-black rounded-full pl-3 pr-3 pt-1 pb-1 "
                        : "bg-white text-black rounded-full pl-3 pr-3 pt-1 pb-1"
                }`}
                onClick={onToggle}
            >
                Quotidienne
            </button>
            <button
                className={`toggle-button ${
                    !isWeekly
                        ? "active bg-gray-200 text-black rounded-full pl-3 pr-3 pt-1 pb-1"
                        : "bg-white text-black rounded-full pl-3 pr-3 pt-1 pb-1"
                }`}
                onClick={onToggle}
            >
                Hebdomadaire
            </button>
        </div>
    );
};

export default ToggleButton;

import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { parse, isValid } from "date-fns";
import esLocale from "@fullcalendar/core/locales/fr";
import interactionPlugin from "@fullcalendar/interaction";
import { useNavigate } from "react-router-dom";

import { FiTrash2 } from "react-icons/fi";

import ToggleButton from "../Buttons/ToggleButton";

import CreateMeetingModal from "../ModalComponents/CreateMeetingModal";

import FastAPIClient from "../../client";
import fast_api_config from "../../config";
const client = new FastAPIClient(fast_api_config);

const parseDate = (dateString) => {
    console.log("dateString", dateString);
    const formats = [
        "M/d/yyyy, h:mm:ss a",
        "d/MM/yyyy, HH:mm:ss",
        "yyyy-MM-dd'T'HH:mm:ss",
        "yyyy-MM-dd'T'HH:mm:ssX",
        "dd/MM/yyyy HH:mm:ss",
        "MM/d/yyyy h:mm:ss a",
        "MMMM yyyy",
        "EEEE, MMMM d, yyyy h:mm:ss a",
        "yyyy-MM-dd HH:mm:ss",
    ];
    for (const format of formats) {
        const parsedDate = parse(dateString, format, new Date());
        console.log("Attempting to parse:", dateString, "with format:", format);
        // Check if the parsed date is valid
        if (isValid(parsedDate)) {
            console.log("Valid date:", parsedDate);
            return parsedDate;
        } else {
            console.log("Invalid date for format:", format);
        }
    }
    throw new Error("Unable to parse date");
};
const formatToTimestamp = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed, so add 1)
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const CalendarModule = ({
    meetings,
    height = 550,
    locale,
    isEditing,
    setIsEditing,
    setOriginalMeetings,
}) => {
    const [newEvents, setnewEvents] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");
    const [messageType, setMessageType] = useState();

    const [meetingUpdated, setMeetingUpdated] = useState({});
    const formatNewEvents = (list) => {
        return list.map((item) => ({
            title: item.client.client_name,
            client_id: item.client.client_id,
            meeting_id: item.meeting_id,
            start: item.start_date,
            end: item.end_date,
            is_visited: item.is_visited,
            type: item.meeting_type_id,
            client_alert: item.client.alert,
            attendees: item.attendees,
        }));
    };
    // Modal related state
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newEventData, setNewEventData] = useState({
        title: "",
        start: "",
        meeting_id: "",
        end: "",
        location: "",
    });
    useEffect(() => {
        setnewEvents(formatNewEvents(meetings));
    }, [meetings, isModalOpen]);

    useEffect(() => {
        updateAllMeetings(meetingUpdated);
    }, [meetingUpdated]);

    const updateAllMeetings = (meetingToUpdate) => {
        setOriginalMeetings((prevMeetings) =>
            prevMeetings.map(
                (meeting) =>
                    meeting.meeting_id === meetingToUpdate.meeting_id
                        ? {
                            ...meeting,
                            start_date: meetingToUpdate.start_date,
                            end_date: meetingToUpdate.end_date,
                        }
                        : meeting // Return unchanged meeting
            )
        );
    };

    const [viewMode, setViewMode] = useState("weekly"); // new state for switching views
    // const location = useLocation(); // Hook to get the current URL
    const navigate = useNavigate(); // Hook to update the URL

    const handleViewToggle = () => {
        const newViewMode = viewMode === "daily" ? "weekly" : "daily";
        setViewMode(newViewMode);
        navigate(`?view=${newViewMode}`); // Update URL with the new view mode
    };

    const validateDrop = (event) => {
        const newStart = event.start;
        const newEnd = event.end;

        // Create Date objects for working hours on the same day as the event
        const workingHoursStart = new Date(newStart);
        workingHoursStart.setHours(8, 0, 0, 0); // 08:00 AM

        const workingHoursEnd = new Date(newStart);
        workingHoursEnd.setHours(20, 0, 0, 0); // 08:00 PM

        // Check if the event falls within working hours
        return newStart >= workingHoursStart && newEnd <= workingHoursEnd;
    };

    const handleEventDrop = (info) => {
        const { event, revert } = info;

        // Validate the drop
        if (!validateDrop(event)) {
            revert();
            // TODO: insert this message in the translations
            setPopupMessage("Les réunions doivent être entre 8h et 20h");
            setMessageType("error");
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 3000);
            return;
        }

        const newStart = event.start.toLocaleString();
        const newEnd = event.end.toLocaleString();

        updateMeeting(
            event.extendedProps.meeting_id,
            parseDate(newStart),
            parseDate(newEnd),
            revert // Pass the revert function to updateMeeting
        );
    };

    const updateMeeting = async (meetingId, newStart, newEnd, revert) => {
        try {
            const payload = {
                meeting_id: meetingId,
                start_date: formatToTimestamp(newStart),
                end_date: formatToTimestamp(newEnd),
                updated_at: new Date().toISOString(),
            };
            console.log(payload);
            await client.updateMeeting(meetingId, payload);
            setMeetingUpdated(payload);
            setPopupMessage(
                `La réunion a été reprogrammée de ${newStart} à ${newEnd}`
            );
            setMessageType("success"); // Set message type to success
            setShowPopup(true); // Show the popup
            setTimeout(() => setShowPopup(false), 3000); // Hide the popup after 3 seconds
        } catch (error) {
            console.error("Failed to update meeting:", error);
            revert(); // Revert the drag if the API call fails
            setPopupMessage("Échec de la reprogrammation de la réunion");
            setMessageType("error"); // Set message type to error
            setShowPopup(true); // Show the popup
            setTimeout(() => setShowPopup(false), 3000); // Hide the popup after 3 seconds
        }
    };

    const openModal = (arg) => {
        // Reset all fields for new meetings
        setNewEventData({
            title: "",
            client_name: "",
            client_id: "",
            start: arg.date,
            end: new Date(arg.date).setHours(arg.date.getHours() + 1),
            meeting_id: "",
            location: "",
            meeting_type_id: "",
            attendees: [],
            client_alert: null,
        });
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        if (isEditing) {
            setIsEditing(false);
        }
    };

    // Function to handle adding an event when a time slot is clicked
    const handleDateClick = (arg) => {
        openModal(arg);
    };

    // Function to handle event navigation
    const handleEventClick = (eventInfo) => {
        setNewEventData({
            title: eventInfo.event.title,
            client_name: eventInfo.event.title,
            client_id: eventInfo.event.extendedProps.client_id,
            start: eventInfo.event.start,
            end: eventInfo.event.end,
            meeting_id: eventInfo.event.extendedProps.meeting_id,
            location: eventInfo.event.extendedProps.location || "",
            meeting_type_id: eventInfo.event.extendedProps.type,
            attendees: eventInfo.event.extendedProps.attendees || [],
        });
        if (!eventInfo.event.extendedProps.is_visited) {
            setIsModalOpen(true);
            setIsEditing(true);
        }
    };

    const handleDeleteEvent = async (eventId) => {
        try {
            console.log(meetings);
            await client.deleteMeeting(eventId); // Call the API to delete the meeting
            // Update the local state
            setOriginalMeetings((prevMeetings) =>
                prevMeetings.filter((meeting) => meeting.meeting_id != eventId)
            );
            setPopupMessage(`La réunion a été supprimée avec succès`);
            setMessageType("success"); // Set message type to success
            setShowPopup(true); // Show the popup
            setTimeout(() => setShowPopup(false), 3000); // Hide the popup after 3 seconds
        } catch (error) {
            console.error("Failed to delete meeting:", error);
            setPopupMessage("Échec de la suppression de la réunion");
            setMessageType("error"); // Set message type to error
            setShowPopup(true); // Show the popup
            setTimeout(() => setShowPopup(false), 3000); // Hide the popup after 3 seconds
        }
    };

    const addNewEvent = (newMeeting) => {
        setnewEvents((prevEvents) => [
            ...prevEvents,
            {
                title: newMeeting.client_name,
                client_id: newMeeting.client_id,
                meeting_id: newMeeting.meeting_id,
                start: newMeeting.start_date,
                end: newMeeting.end_date,
                type: newMeeting.meeting_type_id,
                attendees: newMeeting.attendees, // Add this
                is_visited: false,
                client_alert: newMeeting.client_alert,
                client: {
                    // Add client object structure
                    client_name: newMeeting.client_name,
                    client_id: newMeeting.client_id,
                },
            },
        ]);
        setOriginalMeetings((prevMeetings) => [
            ...prevMeetings,
            {
                meeting_id: newMeeting.meeting_id,
                start_date: newMeeting.start_date,
                end_date: newMeeting.end_date,
                meeting_type_id: newMeeting.meeting_type_id,
                attendees: newMeeting.attendees, // Add this
                is_visited: false,
                client: {
                    // Add client object structure
                    client_name: newMeeting.client_name,
                    client_id: newMeeting.client_id,
                    alert: newMeeting.client_alert,
                },
            },
        ]);
    };

    // update existing event : drag and drop
    const updateExistingEvent = (updatedMeeting) => {
        setnewEvents((prevEvents) =>
            prevEvents.map((event) =>
                event.meeting_id === updatedMeeting.meeting_id
                    ? {
                        title: updatedMeeting.client_name,
                        client_id: updatedMeeting.client_id,
                        meeting_id: updatedMeeting.meeting_id,
                        start: updatedMeeting.start_date,
                        end: updatedMeeting.end_date,
                        type: updatedMeeting.meeting_type_id,
                        attendees: updatedMeeting.attendees, // Add this
                        is_visited: event.is_visited, // Preserve visited status
                        client: {
                            // Add client object structure
                            client_name: updatedMeeting.client_name,
                            client_id: updatedMeeting.client_id,
                            alert: updatedMeeting.client_alert,
                        },
                    }
                    : event
            )
        );
        setMeetingUpdated(updatedMeeting);
    };

    const renderEventContent = (eventInfo) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
                className="h-full"
            >
                <span className="text-black pl-2">
                    {eventInfo.event.title.length > 20
                        ? `${eventInfo.event.title.substring(0, 15)}...`
                        : eventInfo.event.title}
                </span>

                {!eventInfo.event.extendedProps.is_visited && (
                    <FiTrash2
                        style={{ cursor: "pointer", color: "black" }}
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering the event click
                            handleDeleteEvent(eventInfo.event.extendedProps.meeting_id); // Call delete handler
                        }}
                    />
                )}

                {eventInfo.event.extendedProps.client_alert && (
                    <div className="h-full w-1 bg-orange-400 absolute left-0 rounded-l-lg" />
                )}
            </div>
        );
    };

    // based on meeting type, return the color of the event
    const renderEventClassNames = (eventInfo) => {
        // if meeting has been done, return gray background color
        if (eventInfo.event.extendedProps.is_visited) {
            return "bg-gray-200";
        }
        switch (eventInfo.event.extendedProps.type) {
            case "1":
                return "bg-gold";
            case "2":
                return "bg-green-200";
            case "3":
                return "bg-red-200";
            default:
                return "bg-gold";
        }
    };

    const user = JSON.parse(localStorage.getItem("user"));

    return (
        <div className="flex flex-col w-full">
            <div className="flex w-full justify-end items-center mb-5">
                {" "}
                <ToggleButton
                    isWeekly={viewMode === "weekly"}
                    onToggle={handleViewToggle}
                />
            </div>

            <FullCalendar
                locale={esLocale}
                slotMinTime={"08:00:00"}
                slotMaxTime={"20:00:00"}
                plugins={[timeGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                events={newEvents}
                editable={true}
                eventDurationEditable={false} // Disables event resizing
                eventResizableFromStart={false}
                eventDrop={handleEventDrop}
                height={height}
                dateClick={handleDateClick}
                eventClick={handleEventClick}
                eventContent={renderEventContent}
                eventClassNames={(eventInfo) =>
                    `${renderEventClassNames(
                        eventInfo
                    )} border border-gray-200 text-xxxs font-bold text-black uppercase`
                }
            />
            {isModalOpen && (
                <CreateMeetingModal
                    closeModal={closeModal}
                    initialEventData={newEventData}
                    locale={locale}
                    onCreateMeeting={addNewEvent}
                    onUpdateMeeting={updateExistingEvent}
                    user_id={user.user_id}
                    isEditing={isEditing}
                    meetings={meetings}
                />
            )}
            {showPopup && (
                <div
                    className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 bg-white mt-5 mb-5 rounded-lg shadow-lg z-50 ${messageType === "success"
                            ? "border border-gold"
                            : "border border-red-500"
                        }`}
                >
                    {popupMessage}
                </div>
            )}
        </div>
    );
};

export default CalendarModule;

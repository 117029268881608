import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import './index.css';
import build_param from './build_params.json';


const frontendPath = build_param.frontendPath;
const Auth0_domain = build_param.domain;
const Auth0_clientId = build_param.client_id;
const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={Auth0_domain}
      clientId={Auth0_clientId}
      useRefreshTokens={false}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: frontendPath,
        audience: "https://www.api.myway.technology",
        scope: "openid profile email read:appointments offline_access"
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);

import React, { useState, useEffect } from "react";

import Channel from "../../components/Chat/Channel";
import api from "../../components/Chat/mockAPI";
import database from "../../components/Chat/mockData";
import { FiHome } from "react-icons/fi"; // Import the FiHome icon

const Chat = ({ currentUser }) => {
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(1);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        // Simulated fetch for clients and notifications
        const fetchedClients = database.chats.map((chat) => ({
            id: chat.id,
            clientId: chat.client_id,
            name: `${chat.client_id}`,
        }));
        setClients(fetchedClients);

        const fetchedNotifications = api.getNotifications("1");
        setNotifications(fetchedNotifications);
    }, [currentUser]);

    const handleClientClick = (clientId) => {
        setSelectedClient(clientId);
        // Clear notifications for the selected client
        setNotifications(
            notifications.filter((notif) =>
                database.messages.find(
                    (msg) =>
                        msg.id === notif.message_id && msg.chat_id !== clientId
                )
            )
        );
    };

    const getNotificationCount = (clientId) => {
        return notifications.filter((notif) => {
            const message = database.messages.find(
                (msg) => msg.id === notif.message_id
            );
            return message && message.chat_id === clientId;
        }).length;
    };

    console.log(selectedClient);

    return (
        <div style={{ display: "flex", height: "100vh" }}>
            {/* Left Sidebar */}
            <div
                style={{
                    width: "25%",
                    borderRight: "1px solid #ccc",
                    padding: "1rem",
                }}
            >
                <ul style={{ listStyleType: "none", padding: 0 }}>
                    {clients.map((client) => (
                        <li
                            key={client.id}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center", // Ensure vertical alignment
                                cursor: "pointer",
                            }}
                            onClick={() => handleClientClick(client.id)}
                            className={`${
                                selectedClient === client.id
                                    ? "bg-gray-100"
                                    : "bg-white"
                            } border-b border-gray-300 pr-5`}
                        >
                            <div className="flex items-center w-full h-full">
                                {/* Vertical divider */}
                                <div
                                    className={`${
                                        selectedClient === client.id
                                            ? "bg-gold"
                                            : "font-normal"
                                    } rounded-full w-[3px] h-[80px] flex justify-center items-center`}
                                >
                                    {/* Optionally, you can add an icon or content here */}
                                </div>

                                {/* Icon circle */}
                                <div
                                    className={`${
                                        selectedClient === client.id
                                            ? "bg-gray-300"
                                            : "bg-gray-100"
                                    } rounded-full w-[50px] h-[50px] flex justify-center items-center ml-2`}
                                >
                                    <FiHome color="white" />
                                </div>

                                {/* Client name and last message time */}
                                <div className="ml-2 text-left flex-1">
                                    <div
                                        className={`text-base w-full ${
                                            selectedClient === client.id
                                                ? "font-bold"
                                                : "font-normal"
                                        }`}
                                    >
                                        {client.name}
                                    </div>
                                    <div className="text-xs w-full text-gray-500">
                                        Dernière message
                                    </div>
                                </div>

                                {/* Time and Notification */}
                                <div className="ml-auto text-right h-full">
                                    {/* Time of the last message */}
                                    <div className="text-xs text-gray-500 mt-1">
                                        9:30 AM
                                    </div>

                                    {/* Notification badge */}
                                    {getNotificationCount(client.id) > 0 && (
                                        <span className="bg-red-500 text-white rounded-full py-1 px-2 text-xs ml-2">
                                            {getNotificationCount(client.id)}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Right Panel */}
            <div style={{ flex: 1, padding: "1rem" }}>
                {selectedClient ? (
                    <Channel
                        chatId={selectedClient}
                        currentUser={currentUser}
                        clientName={
                            clients.find(
                                (client) => client.id === selectedClient
                            )?.name
                        }
                    />
                ) : (
                    <div>Select a client channel to view the chat</div>
                )}
            </div>
        </div>
    );
};

export default Chat;
